import React, { Component, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom';
import TransactionDetailsContainer from '../containers/transactionDetailsContainer';
import TaxInformationContainer from '../containers/taxIformationContainer';
import NotesContainer from '../containers/notesContainer';
import RelatedAccountsContainer from '../containers/relatedAccountsContainer';
import PaymentHistoryContainer from '../containers/paymentHistoryContainer';
import DocumentsContainer from '../containers/documentsContainer';
import ContactContainer from '../containers/contactContainer';
import PayOffContainer from '../containers/payOffContainer';
import LegalFeeContainer from '../containers/legalFeeContainer';
import Header from '../common/header/header';
import SideNavigation from '../common/sideNavigation';
import SubHeader from './subHeader';
import LoaderComponent from '../common/loaderComponent';
import CustomSnackBarContainer from '../containers/customSnackBarContainer';
import {mapSubHeaderDetails} from '../common/util';
import { withRouter } from 'react-router-dom';

export class AssetDetails extends Component {
    constructor(props) {
        super(props)
        this.state={
            subHeaderDetails: undefined
        }
    }

    componentDidMount () {
        let assetId = JSON.parse(localStorage.getItem("assetId"));
        if (assetId !== null) {
            this.props.getAssetDetails(assetId).then((data) => {
                if(data && data.entity){
                    this.setState({subHeaderDetails: mapSubHeaderDetails(data.entity)});
                }
            })
        } else {
            this.backToSearch()
        }
    }

    backToSearch() {
        this.props.history.push({
            pathname: '/taxease'
        });
    }

    render() {
        return (
            <Fragment>
                <Header></Header>
                <SubHeader details={this.props.subHeaderDetails} ></SubHeader>
                <div className="asset-detail-container">
                    <SideNavigation></SideNavigation>
                    <div className="detail-section">
                        <Switch>
                            <Route exact path="/taxease/asset-details/" component={()=> <TaxInformationContainer/> }  />
                            <Route exact path="/taxease/asset-details/transactions" component={()=> <TransactionDetailsContainer/> }  />
                            <Route exact path="/taxease/asset-details/payment-history" component={()=> <PaymentHistoryContainer/> }  />
                            <Route exact path="/taxease/asset-details/notes" component={()=> <NotesContainer/> }  />
                            <Route exact path="/taxease/asset-details/documents" component={()=> <DocumentsContainer/> }  /> 
                            <Route exact path="/taxease/asset-details/related-accounts" component={()=> <RelatedAccountsContainer/> }  />
                            <Route exact path="/taxease/asset-details/payoff" component={()=> <PayOffContainer/> }  /> 
                            <Route exact path="/taxease/asset-details/fees" component={()=> <LegalFeeContainer/> }  /> 
                            <Route exact path="/taxease/asset-details/contact" component={()=> <ContactContainer /> }  />
                        </Switch>
                    </div>
                </div>
                <LoaderComponent {...this.props} />
                <CustomSnackBarContainer />
            </Fragment>
        )
    }
}

export default withRouter(AssetDetails);
