import React, { Component } from 'react';
import TablePagination from '@material-ui/core/TablePagination';

class CustomTablePagination extends Component {

    constructor(props) {
		super(props)
		this.state = {
        }
    }

    render() {
        return (
            <TablePagination
                className={`pagination`}
                component="div"
                count={this.props.rows.length}
                rowsPerPage={this.props.rowsPerPage}
                page={this.props.page}
                labelRowsPerPage=""
                backIconButtonProps={{
                    'aria-label': 'previous page'
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page'
                }}
                //rowsPerPageOptions={this.state.rowsPerPage < this.props.rows.length ? ['First Page', 'Last Page'] : []}
                rowsPerPageOptions={[]}
                onChangePage={(event,page) => this.props.handleChangePage(event,page)}
                onChangeRowsPerPage={(event) => this.handleChangeRowsPerPage(event)}
                // SelectProps={{
                //     MenuProps: {
                //     className: 'pagination-dropdown',
                //     anchorOrigin: {vertical: "top",horizontal: "left"},
                //     transformOrigin: {vertical: "top",horizontal: "left"},
                //     MenuListProps:{
                //         variant: 'menu',
                //         className: `pagination-list-item ${this.props.rows.length > this.state.rowsPerPage ? 'enable-list' : 'disable-list'}`,
                //     }
                //     },
                    
                // }}
            />
        )
    }
}

export default CustomTablePagination;