import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import LogoSvg from '../../assets/tax-ease-ohio-logo.svg';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';

export class Header extends Component {
    backToHome = () => {
        this.props.history.push({
            pathname: '/taxease/'
        });
    }

    redirectTo = () => {
        window.open("https://viewandpaymybill.com/ebpp/CAZCREEK/Login/Index/","_blank");
    }

    render() {
        return (
            <AppBar className="header" position="sticky">
                <Toolbar className="tool-bar">
                    <Box><img onClick={this.backToHome} className="logo-img" src={LogoSvg} alt="Logo" /></Box>
                    {
                        <div className="help-text new-payment-btn">
                            <Button variant="contained" color="primary" onClick={this.redirectTo}>Make a Payment</Button>
                        </div>
                    }
                </Toolbar>
            </AppBar>
        )
    }
}

export default withRouter(Header);