import { connect } from 'react-redux';
import { mapCertificateInfo } from '../common/util'; 

import Documents from '../components/documents';

import {
	getDownloadFileData,
	getDownloadFileDataSuccess,
	getDownloadFileDataFailure
} from '../actions/documentsAction';


export const mapStateToProps = (state)=>{
	//debugger;
    return {
        certificateInfo: mapCertificateInfo(state.assetDetailsReducer.assetDetails),
    }
}

export const mapDispatchToProps = (dispatch) => {
	return {
        getDownloadFileData: (request) => {
			return new Promise((resolve, reject) => {
				try {
					dispatch(getDownloadFileData(request))
						.then((response) => {
							dispatch(getDownloadFileDataSuccess(response.payload.data))
							resolve(response.payload.data)
						})
						.catch((error) => {
							dispatch(getDownloadFileDataFailure(error))
						})
				} catch (error) {
					reject(error)
				}
			})
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Documents);