import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

export class PageNotFound extends Component {

    backToSearch(e) {
        this.props.history.push({
            pathname: '/taxease/'
        });
    }


    render() {
        return (
            <div className='page-not-found'>
                <div>
                    <Typography variant='h1'>404 Error</Typography>
                    <Typography variant='h4'>Sorry, the page not found</Typography>
                    <Button className="refresh-button" variant="contained" color="primary" onClick={(e) => this.backToSearch(e)}>Refresh</Button>
                </div>
            </div>

        )
    }
}

export default withRouter(PageNotFound);