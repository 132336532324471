import { doActionPost, doActionGet, doActionGetDownload } from '../services/httpRequest';
import { postPayOffGeneratorAPI, getPayOffDownloadAPI, postDocumentUploadAPI } from '../services/apiUrls';
import APIConfig from '../services/appConfig';

export const POST_PAYOFF_GENERATOR = 'POST_PAYOFF_GENERATOR';
export const POST_PAYOFF_GENERATOR_SUCCESS = 'POST_PAYOFF_GENERATOR_SUCCESS';
export const POST_PAYOFF_GENERATOR_FAILURE = 'POST_PAYOFF_GENERATOR_FAILURE';

export const GET_PAYOFF_DOWNLOAD = 'GET_PAYOFF_DOWNLOAD';
export const GET_PAYOFF_DOWNLOAD_SUCCESS = 'GET_PAYOFF_DOWNLOAD_SUCCESS';
export const GET_PAYOFF_DOWNLOAD_FAILURE = 'GET_PAYOFF_DOWNLOAD_FAILURE';

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';

export const REDIRECT_FROM_PAYOFF = 'REDIRECT_FROM_PAYOFF';
export const CLEAN_REDIRECT_FROM_PAYOFF = 'CLEAN_REDIRECT_FROM_PAYOFF';

export const POST_DOCUMENT_UPLOAD = 'POST_DOCUMENT_UPLOAD';

//Post Pay Off Generator API
export const postPayOffGenerator = (request) => {
	const response = doActionPost({ url: postPayOffGeneratorAPI, data: request }, APIConfig.ROOT_URL);
	return {
		type: POST_PAYOFF_GENERATOR,
		payload: response
	}
}

//method call when post Pay Off Generator API successfully
export const postPayOffGeneratorSuccess = (data) => {
	return {
		type: POST_PAYOFF_GENERATOR_SUCCESS,
		payload: data
	}
}

//method call when post Pay Off Generator API failed
export const postPayOffGeneratorFailure = (error) => {
	return {
		type: POST_PAYOFF_GENERATOR_FAILURE,
		payload: error
	}
}

//fetch Pay Off Download from API
export const getPayOffDownload = (id) => {
	const response = doActionGet({ url: getPayOffDownloadAPI + `/${id}` }, APIConfig.ROOT_URL);
	return {
		type: GET_PAYOFF_DOWNLOAD,
		payload: response
	}
}

//method call when Pay Off Download successfully fetched
export const getPayOffDownloadSuccess = (data) => {
	return {
		type: GET_PAYOFF_DOWNLOAD_SUCCESS,
		payload: data
	}
}

//method call when Pay Off Download fail to access
export const getPayOffDownloadFailure = (error) => {
	return {
		type: GET_PAYOFF_DOWNLOAD_FAILURE,
		payload: error
	}
}

export const downloadTemplateFile = (fileId, fileName) => {
    const request = doActionGetDownload(fileId, fileName)
    return {
        type: DOWNLOAD_FILE,
        payload: request
    }
}

export const redirectFromPayOff = (assetIds) => {
    return {
        type: REDIRECT_FROM_PAYOFF,
        payload: assetIds
    }
}

export const cleanRedirectFromPayOff = () => {
    return {
        type: CLEAN_REDIRECT_FROM_PAYOFF,
        payload: []
    }
}

//Post Pay Off Document Upload API
export const postDocumentUpload = (request) => {
	const response = doActionPost({ url: postDocumentUploadAPI, data: request }, APIConfig.ROOT_URL);
	return {
		type: POST_DOCUMENT_UPLOAD,
		payload: response
	}
}