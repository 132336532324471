import React, { Component, Fragment } from 'react';
import CommonTable from '../common/commonTable';
import Typography from '@material-ui/core/Typography';
import { relatedAccountsColumns } from '../const/TableColumns';
import Box from '@material-ui/core/Box';
// import {mapSubHeaderDetails} from '../common/util';
import { withRouter } from 'react-router-dom';

export class RelatedAccounts extends Component {
    navigateToDetailsScreen = (asset) => {
        localStorage.setItem("assetId", JSON.stringify(asset.assetId));
		this.props.getRelatedAssetsDetails(asset.assetId).then((data) => {
            if(data){
                this.props.history.push({
                    pathname: '/taxease/asset-details',
                });
            }
		})
    }

    render() {
        return (
            <Fragment>
                <div className='heading-section'>
                    <Box component='div' className="heading-title"><Typography variant='h1'>Related Accounts</Typography></Box>
                </div>
                <CommonTable 
                    columns={relatedAccountsColumns} 
                    rows={this.props.relatedAssets}
                    handleRowClick={this.navigateToDetailsScreen}
                />
            </Fragment>
        )
    }
}

export default withRouter(RelatedAccounts);
