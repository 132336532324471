//create root reducer, register all your reducer here.
import { combineReducers } from 'redux';
import assetListReducer from './assetListReducer';
import globalSearchReducer from './globalSearchReducer';
import assetDetailsReducer from './assetDetailsReducer';
import contactReducer from './contactReducer';
import loaderReducer from './loaderReducer';
import customSnackBarReducer from './customSnackBarReducer';
import payOffReducer from './payOffReducer';


const rootReducer = combineReducers({
    assets: assetListReducer,
    globalSearch: globalSearchReducer,
    assetDetailsReducer: assetDetailsReducer,
    contact: contactReducer,
    loader: loaderReducer,
    snackBar: customSnackBarReducer,
    payOff: payOffReducer
});

export default rootReducer;