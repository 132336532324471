import {
    FETCH_ASSET_LIST,
    FETCH_ASSET_LIST_SUCCESS,
    FETCH_ASSET_LIST_FAILURE
} from '../actions/assetListAction';

const INITIAL_STATE = {
    assetList: [],
    success: null,
    error: null,
    loading: false
}

export default function assetListReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case FETCH_ASSET_LIST:
            return { ...state, assetList: [], error: null, loading: true };
        case FETCH_ASSET_LIST_SUCCESS:
            return { ...state, assetList: action.payload, error: null, loading: false };
        case FETCH_ASSET_LIST_FAILURE:
            return { ...state, assetList: [], error: action.payload.message, loading: false };
        default:
            return state;
    }
}