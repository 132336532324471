import rootReducer from '../reducers/index';
import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
//import { createLogger } from 'redux-logger'

//const loggerMiddleware = createLogger()

//conigure the store for maintain session
export default function configureStore(initialState) {
	//create store
	const finalCreateStore = compose(
		// applyMiddleware(promise,thunk,loggerMiddleware),
		applyMiddleware(promise, thunk),
		// window.devToolsExtension ? window.devToolsExtension() : (f) => f
		window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
	)(createStore);

	const store = finalCreateStore(rootReducer, initialState);

	if (module.hot) {
		module.hot.accept('../reducers/index', () => {
			const nextReducer = require('../reducers/index');
			store.replaceReducer(nextReducer);
		});
	}

	return store;
}
