import React, { Component, Fragment } from 'react';
import CommonTable from '../common/commonTable';
import CustomTablePagination from '../common/customTablePagination';
import Header from '../common/header/header';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { assetListColumns } from '../const/TableColumns';
import NoRecordFound from '../common/noRecordFound';
import LoaderComponent from '../common/loaderComponent';

export class AssetList extends Component {
    constructor(props){
        super(props);
        this.state = {
            assetList: [],
            page:0,
            rowsPerPage: 10
        };
    }

    backToSearch() {
        this.props.history.push({
            pathname: '/taxease/'
        });
    }

    componentDidMount () {
        if(this.props.isSearched) {
            this.setState({assetList: this.props.globalData});
        } else {
            this.backToSearch();
        }
    }

    handleChangePage(event, page) {
        this.setState({
            page: page
        })
    }

    handleChangeRowsPerPage(event) {
        this.setState({page: event.target.value === "First Page" ? 0 : Math.floor((this.state.assetList.length - 1) / this.state.rowsPerPage)})
    }

    navigateToDetailsScreen =(asset)=> {
        localStorage.setItem("assetId", JSON.stringify(asset.assetId));
        this.props.history.push({
            pathname: '/taxease/asset-details',
        });
    }

    render () {
        return (
            <Fragment>
                <Header></Header>
                <div className="asset-list-section">
                    {this.state.assetList && this.state.assetList.length > 0 ?
                    <Fragment>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className='heading-section'>
                                    <IconButton className="back-button" aria-label="back" onClick={()=>this.backToSearch()}>
                                        <KeyboardBackspaceIcon />
                                    </IconButton>
                                    <Box component='div' className="heading-title"><Typography variant='h1'>Search Results</Typography></Box>
                                    <CustomTablePagination rows={this.state.assetList} rowsPerPage={this.state.rowsPerPage} page={this.state.page} handleChangePage={(event,page) =>this.handleChangePage(event,page)} />
                                </div>
                            </Grid>
                        </Grid>
                        <CommonTable 
                        columns={assetListColumns} 
                        rows={this.state.assetList} 
                        rowsPerPage={this.state.rowsPerPage} 
                        handleRowClick={this.navigateToDetailsScreen}
                        page={this.state.page} />
                    </Fragment>
                    :
                    <NoRecordFound buttonText={'GO BACK TO SEARCH'} showButton={true} handleButton={() => this.backToSearch()} />
                    }
                </div>
                <LoaderComponent {...this.props} />
            </Fragment>
        )
    }
}

export default withRouter(AssetList);