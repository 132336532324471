import { connect } from 'react-redux';
import { mapTaxInformation, mapStatusInformation } from '../common/util'; 

import TaxInformation from '../components/taxInformation';


export const mapStateToProps = (state)=>{
    return {
        taxInformation: mapTaxInformation(state.assetDetailsReducer.assetDetails),
        statusInfo: mapStatusInformation(state.assetDetailsReducer.assetDetails)
    }
}

export default connect(mapStateToProps)(TaxInformation);