import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { NavLink } from 'react-router-dom';

export class SideNavigation extends Component {
    constructor(props){
        super(props);
        this.state = {
            sideNavList: [
                {id: 0, title: 'Tax Certificate Information & Legal Status', url: '/taxease/asset-details', name:'certificate',  isSelected: 'selected',  iconClass: 'certificate-icon'},
                {id: 1, title: 'Transaction Details', url: '/taxease/asset-details/transactions', name:'transaction',  isSelected: 'selected',  iconClass: 'transaction-icon'},
                {id: 2, title: 'Payment History', url: '/taxease/asset-details/payment-history', name:'history',  isSelected: 'selected',  iconClass: 'history-icon'},
                {id: 3, title: 'Notes', url: '/taxease/asset-details/notes', name:'notes',  isSelected: 'selected',  iconClass: 'notes-icon'},
                {id: 4, title: 'Related Accounts', url: '/taxease/asset-details/related-accounts', name:'relatedAccounts',  isSelected: 'selected',  iconClass: 'link-icon'},
                {id: 5, title: 'Payoff', url: '/taxease/asset-details/payoff', name:'payoff',  isSelected: 'selected',  iconClass: 'payoff-icon'},
                {id: 6, title: 'Documents', url: '/taxease/asset-details/documents', name:'documents',  isSelected: 'selected',  iconClass: 'documents-icon'},
                {id: 7, title: 'Fees', url: '/taxease/asset-details/fees', name:'legalFees',  isSelected: 'selected',  iconClass: 'fees-icon'},
                {id: 8, title: 'Contact Us', url: '/taxease/asset-details/contact', name:'contact',  isSelected: 'selected',  iconClass: 'contact-icon'},
            ],
        };
    }

    handleClick = (event, url) => {
        event.preventDefault();
        this.props.history.push({
            pathname: url,
        });
    }

    render () {
        return (
            <div className="side-nav">
                <List component="ul" className="side-nav-list">
                    {this.state.sideNavList.map((link,i) => {
                        return (
                            <ListItem 
                                key={`nav-link-${i}`} 
                                component='li'
                                >
                                <Tooltip arrow title={link.title} placement="right-end">
                                    <ListItemAvatar>
                                        <NavLink 
                                            exact 
                                            to={link.url} 
                                            activeClassName={link.isSelected}
                                            onClick={(event) => this.handleClick(event, link.url)}
                                            >
                                            <Avatar children={<Box component="span" className={`icon ${link.iconClass}`}></Box>} />
                                        </NavLink>
                                    </ListItemAvatar>
                                </Tooltip>
                            </ListItem>
                        )
                    })}
                </List>                        
            </div>
        )
    }
}

export default withRouter(SideNavigation);