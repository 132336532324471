import { connect } from 'react-redux';
import { mapLegalFees, mapServiceFees } from '../common/util'; 

import LegalFees from '../components/legalFees';


export const mapStateToProps = (state)=>{
    return {
        legalFeeDetails: mapLegalFees(state.assetDetailsReducer.assetDetails),
        serviceFeeDetails: mapServiceFees(state.assetDetailsReducer.assetDetails)
    }
}

export default connect(mapStateToProps)(LegalFees);