import {
    POST_PAYOFF_GENERATOR,
    POST_PAYOFF_GENERATOR_SUCCESS,
    POST_PAYOFF_GENERATOR_FAILURE,
    GET_PAYOFF_DOWNLOAD,
    GET_PAYOFF_DOWNLOAD_SUCCESS,
    GET_PAYOFF_DOWNLOAD_FAILURE,
    DOWNLOAD_FILE,
    REDIRECT_FROM_PAYOFF,
    CLEAN_REDIRECT_FROM_PAYOFF
} from '../actions/payOffAction';


const INITIAL_STATE = {
    payOffIds: {},
    loading: false,
    error: null,
    isRedirectFromPayOff: false,
    assetIds: []
}

export default function payOffReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case POST_PAYOFF_GENERATOR:
            return { ...state, payOffIds: {}, error: null, loading: true};
        case POST_PAYOFF_GENERATOR_SUCCESS:
            return { ...state, payOffIds: action.payload, error: null, loading: false};
        case POST_PAYOFF_GENERATOR_FAILURE:
            return { ...state, payOffIds: {}, error: action.payload, loading: false};
        case GET_PAYOFF_DOWNLOAD:
            return { ...state, error: null, loading: false};
        case GET_PAYOFF_DOWNLOAD_SUCCESS:
            return { ...state, error: null, loading: false};
        case GET_PAYOFF_DOWNLOAD_FAILURE:
            return { ...state, error: action.payload, loading: false};
        case DOWNLOAD_FILE:
            return { ...state, error: null, loading: false};
        case REDIRECT_FROM_PAYOFF:
            return {...state, isRedirectFromPayOff:true, assetIds:action.payload}
        case CLEAN_REDIRECT_FROM_PAYOFF:
            return {...state, isRedirectFromPayOff:false, assetIds:[]}
        default:
            return state;
    }
}