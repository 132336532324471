import { connect } from 'react-redux';
import TransactionDetails from '../components/transactionDetails';
// import { getTransactionDetails, getTransactionDetailsSuccess, getTransactionDetailsFailure
// } from '../actions/assetDetailsAction';
import { mapTransactionDetails } from '../common/util'

export const mapStateToProps = (state)=>{
    return {
        // transactionDetails: state.assetDetailsReducer.assetDetails,
        mappedTransactionDetails: mapTransactionDetails(state.assetDetailsReducer.assetDetails)
    }
}

// export const mapDispatchToProps = (dispatch) => {
//     return{
//         getTransactionDetails: (assetId) => {
// 			return new Promise((resolve, reject) => { try {
// 					dispatch(getTransactionDetails(assetId))
// 						.then((response) => { dispatch(getTransactionDetailsSuccess(response.payload.data))
// 							resolve(response.payload.data) })
// 						.catch((error) => { dispatch(getTransactionDetailsFailure(error))})
// 				} catch (error) {reject(error)
// 				}
// 			})
// 		},
//     }
// }

export default connect(mapStateToProps)(TransactionDetails);