import { connect } from 'react-redux';
import GlobalSearch from  '../components/globalSearch';

import {
	getGlobalSearchData,
	getGlobalSearchDataSuccess,
    getGlobalSearchDataFailure,
    getCountyList,
    getCountyListSuccess,
	getCountyListFailure,
	getCaptchaImage,
	getCaptchaImageSuccess,
	getCaptchaImageFailure,
	updateCaptchaText
} from '../actions/globalSearchAction';

import {
	showLoader,
	hideLoader
} from '../actions/loaderAction';

export const mapStateToProps = (state) => {
	return {
        globalSearchData: state.globalSearch.globalSearchData,
		countyList: state.globalSearch.countyList,
		captchaImageData: state.globalSearch.captchaImageData,
		captchaText: state.globalSearch.captchaText,
		loading: state.loader.status,
		error:state.globalSearch.error
	}
}

export const mapDispatchToProps = (dispatch) => {
	return {
        getCountyList: () => {
			return new Promise((resolve, reject) => {
				try {
					dispatch(showLoader())
					dispatch(getCountyList())
						.then((response) => {
							dispatch(getCountyListSuccess(response.payload.data))
							resolve(response.payload.data)
							dispatch(hideLoader())
						})
						.catch((error) => {
							dispatch(getCountyListFailure(error))
							dispatch(hideLoader())
						})
				} catch (error) {
					reject(error)
				}
			})
		},
		getGlobalSearchData: (selectedCounty, searchValue, captchaText, captchaData) => {
			return new Promise((resolve, reject) => {
				try {
					dispatch(showLoader())
					dispatch(getGlobalSearchData(selectedCounty, searchValue, captchaText, captchaData))
						.then((response) => {
							dispatch(getGlobalSearchDataSuccess(response.payload.data.entityList))
							resolve(response.payload.data)
							dispatch(hideLoader())
						})
						.catch((error) => {
							dispatch(getGlobalSearchDataFailure(error.response.data))
							dispatch(hideLoader())
						})
				} catch (error) {
					reject(error)
				}
			}
		)},
		getCaptchaImage: (selectedCounty,searchValue) => {
			return new Promise((resolve, reject) => {
				try {
					dispatch(showLoader())
					dispatch(getCaptchaImage(selectedCounty,searchValue))
						.then((response) => {
							dispatch(getCaptchaImageSuccess(response.payload.data))
							resolve(response.payload.data)
							dispatch(hideLoader())
						})
						.catch((error) => {
							dispatch(getCaptchaImageFailure(error))
							dispatch(hideLoader())
						})
				} catch (error) {
					reject(error)
				}
			},
		)},
		updateCaptchaText: (data) => {
			dispatch(updateCaptchaText(data));
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch)
