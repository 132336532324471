import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LogoSvg from '../assets/tax-ease-ohio-logo.svg';
import InformationIcon from '../assets/icons/information.svg';
import RefreshIcon from '../assets/icons/refresh.svg';
//import CaptchaImg from '../assets/captcha.jpg';
import { withRouter } from 'react-router-dom';
import { createDataForDropdown } from '../common/util';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import LoaderComponent from '../common/loaderComponent';

export class GlobalSearch extends React.Component {

    constructor(props) {
		super(props)
		this.state = {
            countyList: [],
            selectedCountyName:'',
            selectedCountyCode:'',
            searchValue:'',
            isDisabled:true
		}
    }

    componentDidMount() {
        this.getCountyList();
        this.getCaptcha();
    }

    getCaptcha = () => {
        this.props.getCaptchaImage();
    }

    setCaptchaText(e) {
        this.props.updateCaptchaText(e.target.value);
    }

    handleSearchValue = (event) => {
        this.setState({
            searchValue:event.target.value,
            isDisabled:event.target.value.length > 0 ? false : true
        })
    }

    handleSearchButton = (e) => {
        e.preventDefault();
        this.props.getGlobalSearchData(this.state.selectedCountyCode, this.state.searchValue, this.props.captchaText, this.props.captchaImageData.captchaData).then(data=>{
            this.props.history.push({
                pathname: '/taxease/asset-list'
            });
        });
    }

    getCountyList = () => {
        this.props.getCountyList().then((data)=>{
           let formattedCountyList=createDataForDropdown(data.entityList,'jurisdictionCode','jurisdictionCode','id','id','jurisdictionName','jurisdictionName');
           this.setState({
              countyList:formattedCountyList
           })
        });
    }

    handleCountyChange = (event) => {
        let selectedValue=this.state.countyList.filter((x)=>x.jurisdictionCode===event.target.value);
        this.setState({
            selectedCountyCode:selectedValue[0].jurisdictionCode,
            selectedCountyName:selectedValue[0].jurisdictionName
        })
    };

    render() {
        return (
            <form onSubmit={this.handleSearchButton}>
            <Fragment>
                <Grid container direction="row" justify="center" alignItems="center" className="main-container">
                    <Grid item className="search-container">
                        <Paper className="custom-paper search-paper" elevation={3}>
                            <div className="logo-box"><img alt="Logo" src={LogoSvg} /></div>
                            <Grid container>
                                <Grid item xs={12} className="grid-row">
                                    <FormControl>
                                        <InputLabel id="county-select-label">Jurisdiction/County</InputLabel>
                                        <Select
                                        className="county-select"
                                        labelId="county-select-label"
                                        id="county-select"
                                        name="county-select"
                                        value={this.state.selectedCountyName}
                                        onChange={(e)=>this.handleCountyChange(e)} >
                                        {this.state.countyList.map((option) => (
                                            <MenuItem key={option.jurisdictionCode} value={option.jurisdictionCode} >
                                                {option.jurisdictionName}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Box className='icon-box'></Box>
                                </Grid>
                                <Grid item xs={12} className="grid-row">
                                    <TextField id="search" name="search" label="ID #, Enter cert #, Parcel ID, Address, Owner name" 
                                        value={this.state.searchValue} onChange={(e) => this.handleSearchValue(e)} 
                                    />
                                    <Box className='icon-box'>
                                        <Tooltip enterTouchDelay={10} leaveTouchDelay={2000} title="If your Cert ID has a format of OH000000123CC (Note:  numbers and letters may vary), Please enter your Cert ID in search box." arrow>
                                            <img alt='' src={InformationIcon} ></img>
                                        </Tooltip>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} className="grid-row">
                                    <TextField className="captcha-field" id="captcha" name="captcha" error={this.props.error} helperText={this.props.error} label="Enter Captcha" onChange={(e) => this.setCaptchaText(e)}/>
                                    <FormControl className='captcha-box'>
                                        {/* <InputLabel id="county-select-label">Jurisdiction/County</InputLabel> */}
                                        <img alt='' src={this.props.captchaImageData.newCaptchaImage} ></img>
                                    </FormControl>
                                    <Box className='icon-box'>
                                        <Tooltip enterTouchDelay={10} leaveTouchDelay={2000} title="Refresh Captcha" arrow>
                                            <img alt='' src={RefreshIcon} onClick={this.getCaptcha}></img>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" disabled={this.state.isDisabled} variant="contained" color="primary">SEARCH</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        <div className="help-text">
                            <Typography variant="body2">
                                {/* <em>If your Cert ID has a format of 1234567, Please click <a href='https://www.taxliensupport.com/TaxEaseOH/Search.aspx' target="_blank" rel="noreferrer">here</a> to perform your search.</em> */}
                                <em>If you would like to make a payment please click <a href='https://viewandpaymybill.com/ebpp/CAZCREEK/Login/Index/' target="_blank" rel="noreferrer">here</a></em></Typography>
                        </div>
                    </Grid>
                </Grid>
                <LoaderComponent {...this.props} />
            </Fragment>
            </form>
        )
    }
}

export default withRouter(GlobalSearch);