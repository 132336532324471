import React, { Component, Fragment } from 'react';
import Loader from 'react-loaders'

class LoaderComponent extends Component {
    render() {
        return (
            <Fragment>
                {this.props.loading ? <Loader type="ball-pulse" active /> : ''}
            </Fragment>
        )
    }
}

export default LoaderComponent;