import React, { Component, Fragment } from 'react';
import CommonTable from '../common/commonTable';
import Typography from '@material-ui/core/Typography';
import { legalFeesColumn } from '../const/TableColumns';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { a11yProps, TabPanel } from "../common/tabPanel";

export class LegalFees extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0,
        }
    }

    handleChange(val) {
        this.setState({ value: val });
    }

    render() {
        return (
            <Fragment>
                <div className='heading-section'>
                    <Box component='div' className="heading-title"><Typography variant='h1'>Fees</Typography></Box>
                </div>

                <Paper className="custom-paper">
                    <Tabs className="custom-tabs" value={this.state.value} onChange={(e,val) => this.handleChange(val)} aria-label="Fees">
                        <Tab label="Legal Fees " {...a11yProps(0)} />
                        <Tab label="Servicing Fees" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={this.state.value} index={0}>
                        <CommonTable 
                            columns={legalFeesColumn} 
                            rows={this.props.legalFeeDetails}
                        />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        <CommonTable 
                            columns={legalFeesColumn} 
                            rows={this.props.serviceFeeDetails}
                        />
                    </TabPanel>
                </Paper>
               
                <div className="help-text inner-page">
                    <Typography variant="body2">
                        <em><b>INFORMATIONAL PURPOSES ONLY:</b> The information contained on this site is provided for informational purposes only. Some of the information may be dated and may not reflect all fees incurred nor yet to be billed by third parties.</em>
                    </Typography>
                </div>
            </Fragment>
        )

    }
}

export default LegalFees;
