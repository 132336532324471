import { connect } from 'react-redux';
import { mapEventDetails } from '../common/util'; 

import Notes from '../components/notes';


export const mapStateToProps = (state)=>{
    return {
        eventDetails: mapEventDetails(state.assetDetailsReducer.assetDetails),
    }
}

export default connect(mapStateToProps)(Notes);