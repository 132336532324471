import {
    FETCH_GLOBAL_SEARCH_DATA,
    FETCH_GLOBAL_SEARCH_DATA_SUCCESS,
    FETCH_GLOBAL_SEARCH_DATA_FAILURE,
    FETCH_COUNTY_LIST,
    FETCH_COUNTY_LIST_SUCCESS,
    FETCH_COUNTY_LIST_FAILURE,
    FETCH_CAPTCHA_IMAGE,
    FETCH_CAPTCHA_IMAGE_SUCCESS,
    FETCH_CAPTCHA_IMAGE_FAILURE,
    UPDATE_CAPTCHA_TEXT
} from '../actions/globalSearchAction';

const INITIAL_STATE = {
    globalSearchData: {},
    countyList:[],
    captchaImageData:"",
    captchaText:"",
    success: null,
    error: null,
    loading: false,
    isSearched: false
}

export default function globalSearchReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case FETCH_GLOBAL_SEARCH_DATA:
            return { ...state, globalSearchData: {}, error: null, loading: true, isSearched:false };
        case FETCH_GLOBAL_SEARCH_DATA_SUCCESS:
            return { ...state, globalSearchData: action.payload, error: null, loading: false, isSearched:true };
        case FETCH_GLOBAL_SEARCH_DATA_FAILURE:
            return { ...state, globalSearchData: {}, error: action.payload.errorMessage, loading: false, isSearched:false };
        case FETCH_COUNTY_LIST:
            return { ...state, countyList: [], error: null, loading: true };
        case FETCH_COUNTY_LIST_SUCCESS:
            return { ...state, countyList: action.payload, error: null, loading: false };
        case FETCH_COUNTY_LIST_FAILURE:
            return { ...state, countyList: [], error: action.payload.message, loading: false };
        case FETCH_CAPTCHA_IMAGE:
            return { ...state, captchaImageData: "", error: null, loading: true };
        case FETCH_CAPTCHA_IMAGE_SUCCESS:
            return { ...state, captchaImageData: action.payload, error: null, loading: false };
        case FETCH_CAPTCHA_IMAGE_FAILURE:
            return { ...state, captchaImageData: "", error: action.payload.message, loading: false };
        case UPDATE_CAPTCHA_TEXT:
            return { ...state, captchaText: action.payload, error: null, loading: false };
        default:
            return state;
    }
}