// import axios from 'axios';
// import { ApiUrlService } from 'shared/services';
// import { Promise } from 'es6-promise';
import * as CryptoJS from 'crypto-js';


// export const isAuthenticated = () => {
//     return this.getToken() !== null;
// }

// export const getAuthentication = () => {
//     return {
//         headers: { 'token': + this.getToken() }
//     };
// }

// export const getNewToken = () => {
//     return new Promise((resolve, reject) => {
//     axios
//         .post(ApiUrlService.refreshToken(), { refresh_token: this.getRefreshToken() })
//         .then(response => {

//         this.storeToken(response.data.token);
//         this.storeRefreshToken(response.data.refresh_token);

//         resolve(response.data.token);
//         })
//         .catch((error) => {
//         reject(error);
//         });
//     });
// }

// export const storeToken = (token) => {
//         //localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
// }

// export const storeRefreshToken = (refreshToken) => {
//     //localStorage.setItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
// }

// export const clear = () => {
//     //localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
//     //localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
// }

export const getFormattedDateYMDHMS = (date) => { 
    date = date !== undefined && date !== null && date !== "" 
			? new Date(date) 
			: new Date();
	return date.getUTCFullYear() + "-" + 
		   (date.getUTCMonth() + 1) + "-" + 
		   date.getUTCDate() + " " +  
		   date.getUTCHours() + ":" + 
		   date.getUTCMinutes() + ":" + 
		   date.getUTCSeconds()
}

export const getRefreshToken = () => {
    var obj = {
        keySize: 16,
        iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_API_IV),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };
    let currentDate=getFormattedDateYMDHMS(new Date());
    // console.log("Data: "+ currentDate)
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(currentDate), CryptoJS.enc.Utf8.parse(process.env.REACT_APP_API_KEY), obj);
    // console.log('Encrypted: ' + encrypted);
    return encrypted;

    // return this.createUniqueKey();
    // return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
}

// export const getToken = () => {
//     var obj = {
//         keySize: 16,
//         iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_API_IV),
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7
//     };
    
//     var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse("SomeRandomNumber"), CryptoJS.enc.Utf8.parse(process.env.REACT_APP_API_KEY), obj);
//     // console.log('Encrypted: ' + encrypted);
//     return encrypted;

//     //return this.createUniqueKey();
//     //return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
// }

// const createUniqueKey = () => {
//     var obj = {
//         keySize: 16,
//         iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_API_IV),
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7
//     };
    
//     var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse("SomeRandomNumber"), CryptoJS.enc.Utf8.parse(process.env.REACT_APP_API_KEY), obj);
//     console.log('Encrypted: ' + encrypted);
//     return encrypted;
// }
