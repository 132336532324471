import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { Provider } from 'react-redux';
import configureStore from './store/index';

import AssetListContainer from './containers/assetListContainer';
import GlobalSearchContainer from './containers/globalSearchContainer';
import AssetDetailsContainer from './containers/assetDetailsContainer';

import PageNotFound from './common/pageNotFound';

const store = configureStore()

function App() {
  return (
    
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="(/taxease|/)" component={() => <div className="app-body"><GlobalSearchContainer /></div>} />
          <Route exact path="/taxease/asset-list" component={() => <div className="app-body inner-page"><AssetListContainer /></div>} />
          <Route exact path="/taxease/(asset-details|asset-details/transactions|asset-details/payment-history|asset-details/notes|asset-details/related-accounts|asset-details/documents|asset-details/contact|asset-details/payoff|asset-details/fees)" component={() => <div className="app-body inner-page"><AssetDetailsContainer /></div>} />
          <Route path="*" component={() => <div className="app-body not-found"><PageNotFound /></div>} /> 
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
