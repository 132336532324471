import { doActionGet } from '../services/httpRequest';
import { getAssetListApi } from '../services/apiUrls';
import APIConfig from '../services/appConfig';

export const FETCH_ASSET_LIST = 'FETCH_ASSET_LIST';
export const FETCH_ASSET_LIST_SUCCESS = 'FETCH_ASSET_LIST_SUCCESS';
export const FETCH_ASSET_LIST_FAILURE = 'FETCH_ASSET_LIST_FAILURE';

//fetch asset list from API
export const getAssetList = () => {
	const response = doActionGet({ url: getAssetListApi }, APIConfig.ROOT_URL);
	return {
		type: FETCH_ASSET_LIST,
		payload: response
	}
}

//method call when asset list list successfully fetched
export const getAssetListSuccess = (data) => {
	return {
		type: FETCH_ASSET_LIST_SUCCESS,
		payload: data
	}
}

//method call when asset list type fail to access
export const getAssetListFailure = (error) => {
	return {
		type: FETCH_ASSET_LIST_FAILURE,
		payload: error
	}
}
