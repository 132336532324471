import React, { Component, Fragment } from 'react';
import CommonTable from '../common/commonTable';
import Typography from '@material-ui/core/Typography';
import { notesColumns } from '../const/TableColumns';
import Box from '@material-ui/core/Box';

export class Notes extends Component {
    render() {
        return (
            <Fragment>
                <div className='heading-section'>
                    <Box component='div' className="heading-title"><Typography variant='h1'>Notes</Typography></Box>
                </div>
                <CommonTable 
                    columns={notesColumns} 
                    rows={this.props.eventDetails}
                />
            </Fragment>
        )

    }
}

export default Notes;
