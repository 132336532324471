import { connect } from 'react-redux';

import CustomSnackBar from '../common/customSnackBar';

import {
	hideSnackbar
} from '../actions/customSnackBarAction';


export const mapStateToProps = (state)=>{
    return {
        open: state.snackBar.open,
        message: state.snackBar.message,
        messageType: state.snackBar.messageType,
    }
}

export const mapDispatchToProps = (dispatch)=>{
    return {
       hideSnackbar: ()=>{
        dispatch(hideSnackbar())
       }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSnackBar);