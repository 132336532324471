import { doActionPost } from '../services/httpRequest';
import { postContactAPI } from '../services/apiUrls';
import APIConfig from '../services/appConfig';

export const POST_CONTACT = 'POST_CONTACT';
export const POST_CONTACT_SUCCESS = 'POST_CONTACT_SUCCESS';
export const POST_CONTACT_FAILURE = 'POST_CONTACT_FAILURE';
export const UPDATE_CONTACT_FORM_DATA = 'UPDATE_CONTACT_FORM_DATA';

//Post Contact Form API
export const postContactForm = (request) => {
	const response = doActionPost({ url: postContactAPI, data: request }, APIConfig.ROOT_URL);
	return {
		type: POST_CONTACT,
		payload: response
	}
}

//method call when post Contact Form API successfully
export const postContactFormSuccess = (data) => {
	return {
		type: POST_CONTACT_SUCCESS,
		payload: data
	}
}

//method call when post Contact Form API failed
export const postContactFormFailure = (error) => {
	return {
		type: POST_CONTACT_FAILURE,
		payload: error
	}
}

//method call when Contact Form data update
export const updateContactFormData = (data) => {
	return {
		type: UPDATE_CONTACT_FORM_DATA,
		payload: data
	}
}
