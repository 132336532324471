import { 
    SHOW_LOADER,
    HIDE_LOADER
} from '../actions/loaderAction';

const INITIAL_STATE = {
     status: false
}

export default function loaderReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case SHOW_LOADER:
            return { ...state, status: true};
        case HIDE_LOADER:
            return { ...state, status: false};
        default:
            return state;
    }
}