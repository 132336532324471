class APIConfig {
    static get ROOT_URL() { return localStorage.getItem('customerPortalApi'); }

    static loadAPIConfig() {
        if (window.appConfig) {
            localStorage.removeItem('customerPortalApi');
            var customerPortalApi = window.appConfig.customerPortalApi;
            localStorage.setItem('customerPortalApi', customerPortalApi);
        }
    }
}

export default APIConfig;
