import React, { Component, Fragment } from 'react';
import CommonTable from '../common/commonTable';
import Typography from '@material-ui/core/Typography';
import { payOffColumn } from '../const/TableColumns';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {a11yProps, TabPanel } from "../common/tabPanel";
import {payOffJson, documentUploadJson} from "../const/commonConst";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { getFormattedDateYYYYMMDD } from '../common/util';
import popupBannerSvg from '../assets/popup-banner.svg';
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import { downloadFile } from '../common/util';

export class PayOff extends Component {

    constructor(props){
        super(props);
        this.state = {
            payOffAssetDetails: [],
            selectAll: false,
            selectedLength: 0,
            openDialog: false,
            payOffDate: new Date(),
            isPayOffActive: true,
            mainAssetId: '0'
        };
    }

    componentDidMount() {
        if (this.props.assetDetails && this.props.assetDetails.payoffInfo && this.props.assetDetails.payoffInfo.assetDetails) {
            let list = [...this.props.assetDetails.payoffInfo.assetDetails].map((item) => {return {...item, checkStatus: true }});
            list.push(this.addTotalRecord(list));
            this.setState({mainAssetId:this.props.assetDetails.assetId,payOffAssetDetails: list, selectAll: list.length - 1 ? true : false, selectedLength: list.length - 1})
        }
    }

    handlePayOff = () => {
        const {payOffAssetDetails} = this.state;
        let isLegalStatusActive = false;
        payOffAssetDetails.forEach(item => {
            if (item.checkStatus){
                if (item.isForeclosureActivated||item.isThirdPartyForeclosureActivated||item.isBankruptcyActivated) {
                    isLegalStatusActive = true;
                }
            }
        })
        if (isLegalStatusActive) {
            this.setState({isPayOffActive: false, openDialog: true})
        } else {
            this.setState({isPayOffActive: true, openDialog: true}, () => {
                const {payOffDate} = this.state
                let payOffGeneratorData = JSON.parse(JSON.stringify(payOffJson));
                payOffAssetDetails.forEach(item => {if (item.checkStatus) {payOffGeneratorData.assetIds.push(item.assetId)}})
                payOffGeneratorData = {...payOffGeneratorData, payoffDate:getFormattedDateYYYYMMDD(payOffDate)}
                this.props.postPayOffGenerator(payOffGeneratorData).then((data) => {
                    if(data && data.id){
                        let timesRun = 0;
                        var interval = setInterval(() => {
                            timesRun += 1;
                            this.props.getPayOffDownload(data.id).then((filedata) => {
                                if (filedata) {
                                    clearInterval(interval)
                                    downloadFile(filedata);
                                    let documentUploadData = JSON.parse(JSON.stringify(documentUploadJson));
                                    documentUploadData={...documentUploadData,
                                        fileKey: data.id,
                                        mailMergeId: data.mailMergeId,
                                        fileName: filedata.fileName,
                                        payoffDate: getFormattedDateYYYYMMDD(payOffDate),
                                        assetList: payOffGeneratorData.assetIds,
                                    }
                                    this.props.postDocumentUpload(documentUploadData)
                                }
                                if(timesRun === 180){ //stop internal after 15 mins (180)
                                    clearInterval(interval);
                                }
                            })
                        }, 5000)
                    }
                })
            });
        }
    }

    handleAllCheckbox = (e) => {
        let list = [...this.state.payOffAssetDetails];
        let updatedList = list.map((item) => (item.recordRowId !== 'last' && this.state.mainAssetId !== item.assetId)? {...item, checkStatus: e.target.checked } : item);
        let checkedList = updatedList.filter((item) => item.checkStatus === true);
        let recordObj = this.addTotalRecord(checkedList);
        updatedList = updatedList.map((item) => item.recordRowId === 'last' ? recordObj : item);
        this.setState({
            payOffAssetDetails: updatedList,
            selectAll: e.target.checked,
            selectedLength: checkedList.length
        })
    }

    handleCheckboxChange = (e, row) => {
        let list = [...this.state.payOffAssetDetails];
        let updatedList = list.map((item) => (item.assetId === row.assetId) ? {...item, checkStatus: e.target.checked } : item);
        let checkedList = updatedList.filter((item) => item.checkStatus === true);
        let recordObj = this.addTotalRecord(checkedList);
        updatedList = updatedList.map((item) => item.recordRowId === 'last' ? recordObj : item);
        let totalList = updatedList.filter((item) => item.recordRowId !== 'last');
        this.setState({
            payOffAssetDetails: updatedList,
            selectAll: checkedList.length !== totalList.length ? false : true,
            selectedLength: checkedList.length
        })
    }


    addTotalRecord = (list) => {
        let totalRecordObj = {
            recordRowId: 'last', currentInterestRate: 'Total',taxAmount: 0, interestAndPenalty: 0, interestBearingRecoverableFee: 0, recoverableFee: 0, redemptiveValue: 0,
        }
        let propsList = ['taxAmount', 'interestAndPenalty', 'interestBearingRecoverableFee', 'recoverableFee', 'redemptiveValue']
        propsList.forEach((prop)=>{
            let sum = 0;
            list.forEach((row)=>{
                if(row[prop] !== null && row[prop] !== '' && row.recordRowId !== 'last'){
                   let value = row[prop];
                   sum += Number(value);
                } 
            });
            totalRecordObj[prop] = sum;
        })
        return totalRecordObj;
    }

    handleCloseDialog = () => {
        this.setState({openDialog: false})
    }

    handleOpenDialog = () => {
        this.setState({isPayOffActive: true, openDialog: true})
    }

    handleDateChange = (date) => {
        this.setState({payOffDate: date})
    }

    takeToContactUs= () => {
        const {payOffAssetDetails} = this.state;
        let assetIds = [];
        payOffAssetDetails.forEach(item => {if (item.checkStatus) {assetIds.push(item.assetId)}});
        this.props.redirectFromPayOff(assetIds);
        this.props.history.push({
            pathname: '/taxease/asset-details/contact'
        });
    }

    render() {
        const {payOffAssetDetails, selectAll, selectedLength, openDialog, payOffDate, isPayOffActive, mainAssetId} = this.state;
        return (
            <Fragment>
                <div className='heading-section'>
                    <Box component='div' className="heading-title"><Typography variant='h1'>PayOff</Typography></Box>
                    <Box component='div' className="heading-buttons">
                        <Button disabled={selectedLength < 1} className="generate-payoff-btn" variant="contained" color="primary" onClick={this.handleOpenDialog}>Generate payoff</Button>
                    </Box>
                </div>
                <Paper className="custom-paper pay-off-paper">
                    <Tabs className="custom-tabs" value={0} aria-label="Tax Certificate Information & Legal Status">
                        <Tab label="Related Liens" {...a11yProps(0)} />
                    </Tabs>
                    <TabPanel value={0} index={0}>
                        <CommonTable 
                            stylingClass="pay-off-table"
                            columns={payOffColumn} 
                            rows={payOffAssetDetails}
                            componentName={"payOff"}
                            mainAssetId={mainAssetId}
                            handleAllCheckbox={this.handleAllCheckbox}
                            handleCheckboxChange={this.handleCheckboxChange}
                            selectAllCheckboxStatus={selectAll}
                        />
                    </TabPanel>
                </Paper>
                <Dialog className="pay-off-dialog" open={openDialog} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"><img src={popupBannerSvg} alt="" /></DialogTitle>
                    <DialogContent>
                        {isPayOffActive ? (
                            <Fragment>
                                <Grid container justify="space-around">
                                    <Grid item xs={8}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            variant="inline"
                                            disableToolbar
                                            disablePast={true}
                                            label={'Select Pay Off Date'}
                                            onChange={this.handleDateChange}
                                            format="MM-dd-yyyy"
                                            value={payOffDate}
                                            invalidLabel='unknown'
                                        />
                                    </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <DialogContentText>Do you really want to generate payoff?</DialogContentText>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <DialogContentText>Payoff quote cannot be generated as the asset has an legal status active. Please contact customer support by clicking <span className="link" onClick={this.takeToContactUs}>here</span></DialogContentText>
                            </Fragment>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {isPayOffActive && <Button disabled={payOffDate === null} onClick={this.handlePayOff} variant="contained" color="primary">YES</Button>}
                        <Button onClick={this.handleCloseDialog} >Cancel</Button>
                    </DialogActions>
                </Dialog>
                <div className="help-text">
                    <Typography variant="body2">
                        <em><b>Disclaimer :</b> The balance shown is not a payoff amount</em>
                    </Typography>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(PayOff);
