import React, { Component, Fragment } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';

class TransactionDetails extends Component {
    constructor(props) {
        super(props)
        this.state={
            update: false
        }
    }

    // componentDidMount(){
    //     let asset = JSON.parse(localStorage.getItem("assetRecord"))
    //     this.props.getTransactionDetails(asset.assetId).then(resp => {
    //         if(resp)
    //             this.setState({update: true})
    //     })
    // }

    render () {
        const {mappedTransactionDetails} = this.props;
        return (
            <Fragment>
                <div className='heading-section'>
                    <Box component='div' className="heading-title"><Typography variant='h1'>Transaction Details</Typography></Box>
                </div>
                <Paper className="custom-paper">
                    <List className="detail-list">
                        {!!mappedTransactionDetails ? Object.keys(mappedTransactionDetails).map((item, i)=> {
                        return (
                            <ListItem className="detail-list-item" key={`detail-list-${i}`} alignItems="flex-start">
                            <ListItemText
                                primary={item}
                                secondary={mappedTransactionDetails[item] ? mappedTransactionDetails[item] : ""}
                            />
                            </ListItem>
                        )
                        }) : ""}
                    </List>
                </Paper>
            </Fragment>
        )
    }
}

export default TransactionDetails;
