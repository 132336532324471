import { doActionPost } from '../services/httpRequest';
import { postCertificateDownlaodAPI } from '../services/apiUrls';
import APIConfig from '../services/appConfig';

export const FETCH_CERTIFICATE = 'FETCH_CERTIFICATE';
export const FETCH_CERTIFICATE_SUCCESS = 'FETCH_CERTIFICATE_SUCCESS';
export const FETCH_CERTIFICATE_FAILURE = 'FETCH_CERTIFICATE_FAILURE';

//fetch certificate from API
export const getDownloadFileData = (request) => {
	const response = doActionPost({ url: postCertificateDownlaodAPI, data: request }, APIConfig.ROOT_URL);
	return {
		type: FETCH_CERTIFICATE,
		payload: response
	}
}

//method call when certificate successfully fetched
export const getDownloadFileDataSuccess = (data) => {
	return {
		type: FETCH_CERTIFICATE_SUCCESS,
		payload: data
	}
}

//method call certificate fail to access
export const getDownloadFileDataFailure = (error) => {
	return {
		type: FETCH_CERTIFICATE_FAILURE,
		payload: error
	}
}
