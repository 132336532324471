import { connect } from 'react-redux';
import AssetDetails from  '../components/assetDetails';

import {
	showLoader,
	hideLoader
} from '../actions/loaderAction';

import {
	getAssetDetails, getAssetDetailsSuccess, getAssetDetailsFailure, cleanAssetDetails
} from '../actions/assetDetailsAction';

export const mapStateToProps = (state)=>{
    return {
		loading: state.loader.status,
		subHeaderDetails: state.assetDetailsReducer.subHeaderDetails
    }
}

export const mapDispatchToProps = (dispatch) => {
	return {
		getAssetDetails: (assetId) => {
			return new Promise((resolve, reject) => {
				try {
					dispatch(showLoader())
					dispatch(cleanAssetDetails())
					dispatch(getAssetDetails(assetId))
						.then((response) => {
							dispatch(getAssetDetailsSuccess(response.payload.data))
							resolve(response.payload.data)
							dispatch(hideLoader())
						})
						.catch((error) => {
							dispatch(getAssetDetailsFailure(error))
							dispatch(hideLoader())
						})
				} catch (error) {
					reject(error)
				}
			})
		},

	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetDetails);