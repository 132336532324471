import React, { Component, Fragment } from 'react';
import CommonTable from '../common/commonTable';
import Typography from '@material-ui/core/Typography';
import { documentsColumns } from '../const/TableColumns';
import Box from '@material-ui/core/Box';
import { downloadFile } from '../common/util';

export class Documents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDownloadButtonShow:true,
            documentDownloadType:'AssetRecordingDocument',
            documents: [
                // {
                //     category:"Recorded Tax Certificate",
                //     noOfDocuments:"0",
                //     action:"",
                // },
                {
                    category:"Lien and that Transfer Document",
                    noOfDocuments:"0",
                    action:"",
                },
            ]
            
        }
    }

    componentDidMount(){
        let updatedObj=this.state.documents;
        //let isDownloadButtonShow = this.props.certificateInfo && (this.props.certificateInfo.hasCertificateFileName || this.props.certificateInfo.hasTemplateId) ? 1:0;
        let isDownloadButtonShow = this.props.certificateInfo && this.props.certificateInfo.recordingDocument && 
                                  this.props.certificateInfo.recordingDocument.length>0 && this.props.certificateInfo.recordingDocument[0].response
                                  && this.props.certificateInfo.recordingDocument[0].response.length > 0 ? this.props.certificateInfo.recordingDocument[0].response.length:0;
        updatedObj[0].noOfDocuments = isDownloadButtonShow;
        this.setState({
            isDownloadButtonShow: isDownloadButtonShow,
            documents:updatedObj
        })
    }

    setSelectedItemRow = (e,row) => {
        let requestObject={
            'assetId':this.props.certificateInfo.assetId,
            'type':this.state.documentDownloadType
        }
        this.props.getDownloadFileData(requestObject).then((data)=>{
            downloadFile(data);
        })
    }
    

    render() {
        return (
            <Fragment>
                <div className='heading-section'>
                    <Box component='div' className="heading-title"><Typography variant='h1'>Documents</Typography></Box>
                </div>
                <CommonTable 
                    columns={documentsColumns} 
                    rows={this.state.documents}
                    setSelectedItemRow={this.setSelectedItemRow}
                    isDownloadButtonShow={this.state.isDownloadButtonShow}
                    {...this.props}
                />
            </Fragment>
        )

    }
}

export default Documents;
