import { connect } from 'react-redux';
import AssetList from  '../components/assetList';

import {
	getAssetList,
	getAssetListSuccess,
	getAssetListFailure
} from '../actions/assetListAction';

import {
	showLoader,
	hideLoader
} from '../actions/loaderAction';

export const mapStateToProps = (state) => {
	return {
		assetList: state.assets.assetList,
		globalData: state.globalSearch.globalSearchData,
		isSearched:state.globalSearch.isSearched,
		loading: state.loader.status
	}
}

export const mapDispatchToProps = (dispatch) => {
	return {
		getAssetList: () => {
			return new Promise((resolve, reject) => {
				try {
					dispatch(showLoader())
					dispatch(getAssetList())
						.then((response) => {
							dispatch(getAssetListSuccess(response.payload.data))
							resolve(response.payload.data)
							dispatch(hideLoader())
						})
						.catch((error) => {
							dispatch(getAssetListFailure(error))
							dispatch(hideLoader())
						})
				} catch (error) {
					reject(error)
				}
			})
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetList)
