import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

class NoRecordFound extends Component {

    constructor(props) {
		super(props)
		this.state = {
        }
    }

    render() {
        const {handleButton, showButton, buttonText, classes} = this.props;
        return (
            <div className={`no-records ${classes}`}>
                <div>
                    <Typography variant='h1'>NO RECORD FOUND!</Typography>
                    {showButton && 
                    <Button variant="contained" color="primary" onClick={handleButton}>{buttonText}</Button>
                    }
                </div>
            </div>

        )
    }
}

export default NoRecordFound;