import React, { Component, Fragment } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withRouter } from 'react-router-dom';
import SimplePopover from '../common/simplePopover';
import { getDropDownColumnName } from "../common/util";

export class SubHeader extends Component {
    backToSearch() {
      this.props.history.push({
        pathname: '/taxease/asset-list'
      });
    }

    render () {
        const mappedSubHeaderDetails = this.props.details;
        return (
            <Fragment>
                <div className="sub-header">
                  <IconButton className={'back-button'} aria-label="back" onClick={()=>this.backToSearch()}>
                      <KeyboardBackspaceIcon />
                  </IconButton>
                  <List className="sub-header-list">
                    {!!mappedSubHeaderDetails ? Object.keys(mappedSubHeaderDetails).map((item, i)=> {
                      return (
                        <ListItem className={`sub-header-list-item ${i>1 && 'hide-on-small'}`} key={`list-${i}`} alignItems="flex-start">
                            <Fragment>
                              <ListItemText
                                primary={item}
                                secondary={Array.isArray(mappedSubHeaderDetails[item]) ?
                                  <SimplePopover component='subHeader' dropDownTitle={item} dropDownColumnName={getDropDownColumnName(item)} dropDownList={mappedSubHeaderDetails[item]} />
                                  :
                                  <Fragment>
                                    <span>{mappedSubHeaderDetails[item]}</span>
                                    {item ==="Remaining Balance" && <Tooltip arrow enterTouchDelay={10} leaveTouchDelay={2000} title="The Remaining Balance set forth above may be greater than the amount shown herein and is subject to change due to unknown costs or expenses" placement="bottom"><span><InfoOutlinedIcon /></span></Tooltip>}
                                  </Fragment>
                                }
                              />
                            </Fragment>
                        </ListItem>
                      )
                    }) : ""}
                  </List>
                  {mappedSubHeaderDetails && 
                    <div className="show-on-small">
                      <SimplePopover component='subHeader-on-small-screen' dropDownTitle='' dropDownColumnName='' dropDownList={mappedSubHeaderDetails} />
                    </div>
                  }
                </div>
            </Fragment>
        )
    }
}

export default withRouter(SubHeader);