// column for Asset List
export const assetListColumns = [
    {'id': 'assetId', 'numeric': false, 'disablePadding': false, 'label': 'LIEN ID', 'sorting': false},
    {'id': 'parcelId', 'numeric': false, 'disablePadding': false, 'label': 'PARCEL ID', 'sorting': false},
    {'id': 'jurisdiction', 'numeric': false, 'disablePadding': false, 'label': 'JURISDICTION/COUNTY', 'sorting': false},
    {'id': 'propertyDetails', 'numeric': false, 'disablePadding': false, 'label': 'PROPERTY ADDRESS', 'sorting': false},
    {'id': 'propertyType', 'numeric': false, 'disablePadding': false, 'label': 'PROPERTY TYPE', 'sorting': false},
    {'id': 'ownerDetails', 'numeric': false, 'disablePadding': false, 'label': 'OWNER NAMES', 'sorting': false},
    {'id': 'mailingAddress', 'numeric': false, 'disablePadding': false, 'label': 'MAILING ADDRESS', 'sorting': false},
]

// column for Payment History
export const paymentHistoryColumns = [
    {'id': 'glDateFormatted', 'numeric': false, 'disablePadding': false, 'label': 'PAYMENT DATE', 'sorting': false},
    {'id': 'postingAmount', 'numeric': false, 'disablePadding': false, 'label': 'PAYMENT AMOUNT', 'sorting': false},
]

// column for Notes
export const notesColumns = [
    {'id': 'effectiveDateFormatted', 'numeric': false, 'disablePadding': false, 'label': 'EFFECTIVE DATE', 'sorting': false},
    {'id': 'eventTypeName', 'numeric': false, 'disablePadding': false, 'label': 'EVENT TYPE', 'sorting': false},
    {'id': 'eventActionName', 'numeric': false, 'disablePadding': false, 'label': 'ACTION TYPE', 'sorting': false},
    {'id': 'eventActionCategoryName', 'numeric': false, 'disablePadding': false, 'label': 'ACTION CATEGORY', 'sorting': false},
]


// column for Related Accounts
export const relatedAccountsColumns = [
    {'id': 'assetId', 'numeric': false, 'disablePadding': false, 'label': 'LIEN ID', 'sorting': false},
    {'id': 'parcelId', 'numeric': false, 'disablePadding': false, 'label': 'PARCEL ID', 'sorting': false},
    {'id': 'jurisdiction', 'numeric': false, 'disablePadding': false, 'label': 'JURISDICTION/COUNTY', 'sorting': false},
    {'id': 'propertyDetails', 'numeric': false, 'disablePadding': false, 'label': 'PROPERTY ADDRESS', 'sorting': false},
    {'id': 'propertyType', 'numeric': false, 'disablePadding': false, 'label': 'PROPERTY TYPE', 'sorting': false},
    {'id': 'ownerDetails', 'numeric': false, 'disablePadding': false, 'label': 'OWNER NAMES', 'sorting': false},
    {'id': 'mailingAddress', 'numeric': false, 'disablePadding': false, 'label': 'MAILING ADDRESS', 'sorting': false},
]

// column for Documents
export const documentsColumns = [
    {'id': 'category', 'numeric': false, 'disablePadding': false, 'label': 'CATEGORY', 'sorting': false},
    {'id': 'noOfDocuments', 'numeric': false, 'disablePadding': false, 'label': 'NO. OF DOCUMENTS', 'sorting': false},
    {'id': 'action', 'numeric': false, 'disablePadding': false, 'label': 'ACTION', 'sorting': false},
]

//column for PayOff Related Asset Table
export const payOffColumn = [
    {'id': 'checkbox', 'numeric': false, 'disablePadding': false, 'label': '', 'sorting': false},
    {'id': 'assetId', 'numeric': false, 'disablePadding': false, 'label': 'Lien ID', 'sorting': false},
    {'id': 'lienOriginationDateString', 'numeric': false, 'disablePadding': false, 'label': 'Acquisition Date', 'sorting': false},
    //{'id': 'lienExpirationDateString', 'numeric': false, 'disablePadding': false, 'label': 'Expiration Date', 'sorting': false},
    {'id': 'currentInterestRate', 'numeric': false, 'disablePadding': false, 'label': 'Current Interest Rate', 'sorting': false},
    {'id': 'taxAmount', 'numeric': true, 'disablePadding': false, 'label': 'Tax amount balance ($)', 'sorting': false},
    {'id': 'interestAndPenalty', 'numeric': true, 'disablePadding': false, 'label': 'Interest & penalty balance ($)', 'sorting': false},
    {'id': 'interestBearingRecoverableFee', 'numeric': true, 'disablePadding': false, 'label': 'Interest bearing Recoverable fee ($)', 'sorting': false},
    {'id': 'recoverableFee', 'numeric': true, 'disablePadding': false, 'label': 'Recoverable fee ($)', 'sorting': false},
    {'id': 'redemptiveValue', 'numeric': true, 'disablePadding': false, 'label': 'Redemptive Value ($)', 'sorting': false},
]

//column for Legal Fees Table
export const legalFeesColumn = [
    {'id': 'subBucket', 'numeric': false, 'disablePadding': false, 'label': 'Fee Type', 'sorting': false},
    {'id': 'transactionDate', 'numeric': false, 'disablePadding': false, 'label': 'Transaction Date', 'sorting': false},
    {'id': 'effectiveDate', 'numeric': false, 'disablePadding': false, 'label': 'Effective Date', 'sorting': false},
    {'id': 'inputAmount', 'numeric': false, 'disablePadding': false, 'label': 'Amount', 'sorting': false},
]