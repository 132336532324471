//Contact Form Json Data
export const contactFormDataJson = {
    name: "",
    phoneNumber: "",
    email: "",
    reason: "",
    reasonCode: "",
    query: "",
    isPayOffQuoteRequested: false
}

export const documentUploadJson = {
  fileKey: "",
  mailMergeId: "",
  fileName: "",
  payoffDate: "",
  assetList: [],
  userName: "",
  userId: "00000000-0000-0000-0000-000000000000"
}

//Email validation regex
export const EMAIL_REGEX = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

//Phone validation regex
export const PHONE_REGEX = /^(\+\d{1,2}\s?)?1?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;

//Pay Off Json Data
export const payOffJson = {
  assetIds: [],
  userId: "00000000-0000-0000-0000-000000000000",
  isBulkData: false,
  userName: "",
  payoffDate: ""
}

// SnackBar Messages
export const snackBarMessages = {
  submitSuccess: 'Submit successfully.',
  payOffSubmitSuccess: 'A payoff quote request has been sent to the Servicing department and you will receive a response in 24-48 hours',
  requiredFields: 'Please update the required fields.',
  error: 'Some error occurred. Please try again later.',
  showfileDownloadMsg : 'File download is in progress. It will take some time to download.',
};