import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {a11yProps, TabPanel } from "../common/tabPanel";

export class TaxInformation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0,
        }
    }

    handleChange(val) {
        this.setState({ value: val });
    }

    render() {
        return (
            <Fragment>
                <div className='heading-section'>
                    <Box component='div' className="heading-title"><Typography variant='h1'>Tax Certificate Information & Legal Status</Typography></Box>
                </div>
                <Paper className="custom-paper">
                    <Tabs className="custom-tabs" value={this.state.value} onChange={(e,val) => this.handleChange(val)} aria-label="Tax Certificate Information & Legal Status">
                        <Tab label="Tax Certificate " {...a11yProps(0)} />
                        <Tab label="Legal Status" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={this.state.value} index={0}>
                        <List className="detail-list">
                            {!!this.props.taxInformation ? Object.keys(this.props.taxInformation).map((item, i)=> {
                            return (
                                <ListItem className="detail-list-item" key={`detail-list-${i}`} alignItems="flex-start">
                                <ListItemText
                                    primary={item}
                                    secondary={this.props.taxInformation[item] ? this.props.taxInformation[item] : "-"}
                                />
                                </ListItem>
                            )
                            }) : ""}
                        </List>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        <List className="detail-list">
                            {!!this.props.statusInfo ? Object.keys(this.props.statusInfo).map((item, i)=> {
                            return (
                                <ListItem className="detail-list-item" key={`detail-list-${i}`} alignItems="flex-start">
                                <ListItemText
                                    primary={item}
                                    secondary={
                                        <Fragment>
                                            <Box component="span" className={this.props.statusInfo[item] === 'Active' ? 'status active': 'status inactive'}>
                                                {this.props.statusInfo[item]}
                                            </Box>
                                        </Fragment>
                                    }
                                />
                                </ListItem>
                            )
                            }) : ""}
                        </List>
                    </TabPanel>
                </Paper>
                <div className="help-text inner-page">
                    <Typography variant="body2">
                        <em><b>INFORMATIONAL PURPOSES ONLY:</b> The information contained on this site is provided for informational purposes only. Some of the information may be dated and may not reflect the most current legal developments. You should not act or refrain from acting on the basis of any content included in this site without seeking legal or other professional advice.</em>
                    </Typography>
                </div>
            </Fragment>
        )

    }
}

export default TaxInformation;
