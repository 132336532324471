import React, { Component, Fragment } from 'react';
import CommonTable from '../common/commonTable';
import Typography from '@material-ui/core/Typography';
import { paymentHistoryColumns } from '../const/TableColumns';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { downloadFile } from '../common/util';

import downloadIcon from '../assets/icons/download.svg'

export class PaymentHistory extends Component {
    constructor(props) {
        super(props)
        this.state={
            update: false
        }
    }

    // componentDidMount(){
    //     let asset = JSON.parse(localStorage.getItem("assetRecord"))
    //     this.props.getPaymentHistory(asset.assetId).then(resp => {
    //         if(resp)
    //             this.setState({update: true})
    //     })
    // }

    downloadPaymentHistory = () => {
        let {assetDetails} = this.props
        downloadFile(assetDetails.paymentHistoryFileData)
    }

    render() {
        let {assetDetails} = this.props
        return (
            <Fragment>
                <div className='heading-section'>
                    <Box component='div' className="heading-title"><Typography variant='h1'>Payment History</Typography></Box>
                    <Box component='div'>
                        {assetDetails && assetDetails.paymentHistory &&
                        <IconButton className='square-icon-button' onClick={this.downloadPaymentHistory}><img alt='Download Icon' src={downloadIcon} /></IconButton>
                        }
                    </Box>
                </div>
                <CommonTable 
                    columns={paymentHistoryColumns} 
                    rows={assetDetails && assetDetails.paymentHistory ? assetDetails.paymentHistory : []}
                />
            </Fragment>
        )

    }
}

export default PaymentHistory;
