import { connect } from 'react-redux';
import {
	getAssetDetails, getAssetDetailsSuccess, getAssetDetailsFailure
} from '../actions/assetDetailsAction';
import {
	showLoader,
	hideLoader
} from '../actions/loaderAction';
import RelatedAccounts from '../components/relatedAccounts';

export const mapStateToProps = (state)=>{
    return {
        relatedAssets: state.assetDetailsReducer.assetDetails.relatedAssets,
    }
}

export const mapDispatchToProps = (dispatch) => {
	return {
		getRelatedAssetsDetails: (assetId) => {
			return new Promise((resolve, reject) => {
				try {dispatch(showLoader()); dispatch(getAssetDetails(assetId))
						.then((response) => { dispatch(getAssetDetailsSuccess(response.payload.data))
							resolve(response.payload.data); dispatch(hideLoader())})
						.catch((error) => {	dispatch(getAssetDetailsFailure(error));dispatch(hideLoader())})} catch (error) {
					reject(error)
				}
			})
		},

	}
}

export default connect(mapStateToProps,mapDispatchToProps)(RelatedAccounts);