import { connect } from 'react-redux';

import Contact from '../components/contact';
import {snackBarMessages} from '../const/commonConst'

import {
	updateContactFormData,
	postContactForm,
	postContactFormSuccess,
	postContactFormFailure
} from '../actions/contactAction';

import {
	cleanRedirectFromPayOff
} from '../actions/payOffAction';

import {
	showLoader,
	hideLoader
} from '../actions/loaderAction';

import {
	showSnackbar,
	errorSnackbar
} from '../actions/customSnackBarAction';

export const mapStateToProps = (state)=>{
    return {
        loading: state.loader.status,
		assetDetails: state.assetDetailsReducer.assetDetails,
		contactFormData: state.contact.contactFormData,
        message: state.contact.message,
        isSuccess: state.contact.isSuccess,
        isError: state.contact.isError,
		isRedirectFromPayOff: state.payOff.isRedirectFromPayOff,
		assetIds: state.payOff.assetIds,
    }
}

export const mapDispatchToProps = (dispatch) => {
	return {
        postContactForm: (request, isPayOff) => {
			return new Promise((resolve, reject) => {
				try {
                    dispatch(showLoader())
					dispatch(postContactForm(request))
						.then((response) => {
							dispatch(postContactFormSuccess(response.payload.data))
                            resolve(response.payload.data)
							dispatch(hideLoader())
							dispatch(showSnackbar(isPayOff ? snackBarMessages.payOffSubmitSuccess : snackBarMessages.submitSuccess))
							
						})
						.catch((error) => {
                            dispatch(postContactFormFailure(error))
							dispatch(hideLoader())
							dispatch(errorSnackbar(snackBarMessages.error))
						})
				} catch (error) {
					reject(error)
				}
			})
		},
		updateContactFormData: (data) => {
			dispatch(updateContactFormData(data))
		},
		cleanRedirectFromPayOff: () => {
			dispatch(cleanRedirectFromPayOff())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);