import { doActionGet } from '../services/httpRequest';
import { getAssetDetailsApi } from '../services/apiUrls';
import APIConfig from '../services/appConfig';

export const GET_ASSET_DETAILS = 'GET_ASSET_DETAILS';
export const GET_ASSET_DETAILS_SUCCESS = 'GET_ASSET_DETAILS_SUCCESS';
export const GET_ASSET_DETAILS_FAILURE = 'GET_ASSET_DETAILS_FAILURE';

//fetch asset details from API
export const getAssetDetails = (assetId) => {
	const response = doActionGet({ url: getAssetDetailsApi + `/${assetId}` }, APIConfig.ROOT_URL);
	return {
		type: GET_ASSET_DETAILS,
		payload: response
	}
}

//method call when asset details successfully fetched
export const getAssetDetailsSuccess = (data) => {
	return {
		type: GET_ASSET_DETAILS_SUCCESS,
		payload: data
	}
}

//method call when asset details type fail to access
export const getAssetDetailsFailure = (error) => {
	return {
		type: GET_ASSET_DETAILS_FAILURE,
		payload: error
	}
}

//method call when old asset details need to clean
export const cleanAssetDetails = () => {
    return {
        type: GET_ASSET_DETAILS
    }
}


