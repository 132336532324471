export function createDataForDropdown(list,field1,value1,field2,value2,field3, value3,dropdownName=""){
	if(list.length>0){
		var updatedList = [];
	    for(let i=0;i<list.length;i++){
			let record={};
			record[field1]=list[i][value1];
			record[field2]=list[i][value2];
			record[field3]=list[i][value3];
			updatedList.push(record);
		}
		return updatedList;
	}
}

export function symbolWithTwoDecimal(num) {
	num = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num)	
	return num;
}

export function mapSubHeaderDetails(assetDetails) {
	if(Object.keys(assetDetails).length !== 0 ){
		let subHeaderDetails = {
			'Lien ID': assetDetails.assetId,
			'Parcel ID': assetDetails.propertyDetails && assetDetails.propertyDetails.length > 0 ? assetDetails.propertyDetails : "",
			'Jurisdiction/County': assetDetails.jurisdiction,
			'Property Address': assetDetails.propertyDetails && assetDetails.propertyDetails.length > 0 ? assetDetails.propertyDetails : "",
			'Property Type': assetDetails.propertyDetails && assetDetails.propertyDetails.length > 0 ? assetDetails.propertyDetails : "",
			'Owner Name': assetDetails.ownerDetails && assetDetails.ownerDetails.length > 0 ? assetDetails.ownerDetails : "",
			'Mailing Address': assetDetails.ownerDetails && assetDetails.ownerDetails.length > 0 ? assetDetails.ownerDetails : "",
			'Remaining Balance': symbolWithTwoDecimal(assetDetails.redemptiveValue)
		}
		return subHeaderDetails
	}
}

export function mapTransactionDetails(assetDetails) {
	if(Object.keys(assetDetails).length !== 0 ){
		let transactionDetailObj = {
			'Principal amount': assetDetails.transactionDetails.initTaxAmount ? symbolWithTwoDecimal(assetDetails.transactionDetails.initTaxAmount) : "-",
			'Accrued Interest': assetDetails.transactionDetails.accruedInterest ? symbolWithTwoDecimal(assetDetails.transactionDetails.accruedInterest) : "-",
			'Fee': assetDetails.transactionDetails.totalFee ? symbolWithTwoDecimal(assetDetails.transactionDetails.totalFee) : "-",
			'Adjustment': assetDetails.transactionDetails.adjustment ? symbolWithTwoDecimal(assetDetails.transactionDetails.adjustment) : "-",
			'Payments': assetDetails.transactionDetails.payments ? symbolWithTwoDecimal(assetDetails.transactionDetails.payments) : "-",
			'Remaining Balance': assetDetails.transactionDetails.redemptiveVal ? symbolWithTwoDecimal(assetDetails.transactionDetails.redemptiveVal) : "-" 
		}
		return transactionDetailObj
	}
}

export function mapTaxInformation(transactionDetails) {
	if(Object.keys(transactionDetails).length !== 0 ){
		let taxCertificateInfoObj = {
			'Original Principal Amount': transactionDetails.taxCertificateInfo.principalAmount ? symbolWithTwoDecimal(transactionDetails.taxCertificateInfo.principalAmount) : "-",
			'Purchase Date': transactionDetails.taxCertificateInfo.acquisitionDateFormatted ? transactionDetails.taxCertificateInfo.acquisitionDateFormatted : "-",
			'Lien Recording Date': transactionDetails.taxCertificateInfo.recordedDateFormatted ?  transactionDetails.taxCertificateInfo.recordedDateFormatted : "-",
			// 'Repeats & Subs': "-",
		}
		return taxCertificateInfoObj
	}
    
}

export function mapStatusInformation(transactionDetails) {
	if(Object.keys(transactionDetails).length !== 0 ){
		let statusInfoObject = {
			'Foreclosure': transactionDetails.statusInfo.foreclosureStatusCode ? transactionDetails.statusInfo.foreclosureStatusCode : "-",
			'Bankruptcy': transactionDetails.statusInfo.bankruptcyStatusCode ?  transactionDetails.statusInfo.bankruptcyStatusCode : "-",
			'3rd Party Foreclosure': transactionDetails.statusInfo.thirdPartyForeclosureStatusCode ?  transactionDetails.statusInfo.thirdPartyForeclosureStatusCode : "-",
			'Payment Plan': transactionDetails.statusInfo.planStatus ?  transactionDetails.statusInfo.planStatus : "-",
		}
		return statusInfoObject
	}
}

export function mapEventDetails(transactionDetails) {
	let eventDetailObject = [];
	if(Object.keys(transactionDetails).length !== 0 && transactionDetails.eventDetails!==null){
		eventDetailObject = (transactionDetails.eventDetails).map((item)=>{
			item['effectiveDateFormatted'] = item.effectiveDateFormatted  ? item.effectiveDateFormatted  : "-"
			item['eventTypeName'] = item.eventTypeName  ? item.eventTypeName  : "-"
			item['eventActionName'] = item.eventActionName  ? item.eventActionName  : "-"
			item['eventActionCategoryName'] = item.eventActionCategoryName  ? item.eventActionCategoryName  : "-"
			return item;
		})
	}
	return eventDetailObject;
}

export function mapCertificateInfo(transactionDetails) {
	if(Object.keys(transactionDetails).length !== 0 ){
		let certiInfoObject = {
			'assetId': transactionDetails.certificateInfo.assetId,
			'hasCertificateFileName': transactionDetails.certificateInfo.hasCertificateFileName,
			'hasTemplateId': transactionDetails.certificateInfo.hasTemplateId,
			'recordingDocument':transactionDetails.certificateInfo.recordingDocument
		}
		return certiInfoObject
	}
}

export function mapLegalFees(assetDetails) {
	let mapLegalFeeDetails = [];
	if(assetDetails && assetDetails.legalFeeDetails){
		const legalFeeDetails = JSON.parse(JSON.stringify(assetDetails.legalFeeDetails));
		mapLegalFeeDetails =  legalFeeDetails.map((item)=> {
			item['inputAmount'] = symbolWithTwoDecimal(item.inputAmount);
			item['transactionDate'] = getFormattedDateMMDDYYYY(item.transactionDate);
			item['effectiveDate'] = getFormattedDateMMDDYYYY(item.effectiveDate);
			return item;
		})
	}
	return mapLegalFeeDetails;
}

export function mapServiceFees(assetDetails) {
	let mapServiceFeeDetails = [];
	if(assetDetails && assetDetails.serviceFeeDetails){
		const serviceFeeDetails = JSON.parse(JSON.stringify(assetDetails.serviceFeeDetails));
		mapServiceFeeDetails =  serviceFeeDetails.map((item)=> {
			item['inputAmount'] = symbolWithTwoDecimal(item.inputAmount);
			item['transactionDate'] = getFormattedDateMMDDYYYY(item.transactionDate);
			item['effectiveDate'] = getFormattedDateMMDDYYYY(item.effectiveDate);
			return item;
		})
	}
	return mapServiceFeeDetails;
}

export function getDropDownColumnName(item) {
	switch(item) {
		case 'Owner Name':
			return 'ownerName';
		case 'Parcel ID':
			return 'parcelId';
		case 'Property Address':
			return 'propertyAddress';
		case 'Property Type':
			return 'propertyType';
		case 'Mailing Address':
			return 'ownerAddress';
		default:
			return '';
	}
}

export function downloadFile (fileData) {
	if(fileData && fileData.fileStream){
		let binaryString = window.atob(fileData.fileStream);
		let binaryLen = binaryString.length;
		let bytes = new Uint8Array(binaryLen);

		for (let i = 0; i < binaryLen; i++) {
			let ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}

		let blob = new Blob([bytes], {type: fileData.fileName.split('.')[1]});
		let link = document.createElement('a');

		link.href = window.URL.createObjectURL(blob);
		link.download = fileData.fileName;
		link.click();
	}
}

export const getFormattedDateYYYYMMDD = (date) => { // this is for api request json only
	date = date !== undefined && date !== null && date !== "" 
			? new Date(date)
			: new Date();
	return (date.getFullYear()) + '-'+ 
			((date.getMonth() > 8) 
			? (date.getMonth() + 1) 
			: ('0' + (date.getMonth() + 1))) + '-' + 
			((date.getDate() > 9) 
			? date.getDate() 
			: ('0' + date.getDate()))
}

export const getFormattedDateMMDDYYYY = (date) => { // this is for api request json only
	date = date !== undefined && date !== null && date !== "" 
			? new Date(date)
			: new Date();
	return ((date.getMonth() > 8) 
			? (date.getMonth() + 1) 
			: ('0' + (date.getMonth() + 1))) + '-' + 
			((date.getDate() > 9) 
			? date.getDate() 
			: ('0' + date.getDate())) + '-' + (date.getFullYear())
}