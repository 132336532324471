import {
    POST_CONTACT, POST_CONTACT_SUCCESS, POST_CONTACT_FAILURE, UPDATE_CONTACT_FORM_DATA
} from '../actions/contactAction';

import {contactFormDataJson} from '../const/commonConst'

const INITIAL_STATE = {
    contactFormData:contactFormDataJson,
    loading: false
}

export default function contactReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case POST_CONTACT:
            return { ...state, loading:true};
        case POST_CONTACT_SUCCESS:
            return { ...state, loading:false};
        case POST_CONTACT_FAILURE:
            return { ...state, loading:false};
        case UPDATE_CONTACT_FORM_DATA:
            return { ...state, contactFormData:action.payload};
        default:
            return state;
    }
}