import React, { Component} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class CustomSnackBar extends Component {
    handleClose = () => {
        this.props.hideSnackbar();
    }

    render() {
        const {message, open, messageType} = this.props;
        const messageDuration = (message.length/2) * 200;
        const hideDuration = messageDuration > 6000 ? messageDuration : 6000;
        return (
            <Snackbar open={open} autoHideDuration={hideDuration} className={`custom-snackbar ${messageType}`} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity={messageType}>{message}</Alert>
            </Snackbar>
        )
    }
}

export default CustomSnackBar;