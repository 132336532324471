
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
// export const UPDATE_SNACKBAR = 'UPDATE_SNACKBAR';
// export const DELETE_SNACKBAR = 'DELETE_SNACKBAR';
export const ERROR_SNACKBAR = 'ERROR_SNACKBAR';

export const showSnackbar = (data) => {
	return {
		type: SHOW_SNACKBAR,
		payload: data
	}
}

// export const updateSnackbar = (data) => {
// 	return {
// 		type: UPDATE_SNACKBAR,
// 		payload: data
// 	}
// }

// export const deleteSnackbar = (data) => {
// 	return {
// 		type: DELETE_SNACKBAR,
// 		payload: data
// 	}
// }

export const errorSnackbar = (data) => {
	return {
		type: ERROR_SNACKBAR,
		payload: data
	}
}

export const hideSnackbar = () => {
	return {
		type: HIDE_SNACKBAR
	}
}