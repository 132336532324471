import React, { Component, Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import SimplePopover from './simplePopover';
import NoRecordFound from '../common/noRecordFound';
import IconButton from '@material-ui/core/IconButton';
import ViewDocumentIcon from '../assets/icons/view-document.svg';
import {symbolWithTwoDecimal} from '../common/util';


class CommonTable extends Component {

    constructor(props) {
		super(props)
		this.state = {}
    }

    renderTableHead() {
        const {columns, selectAllCheckboxStatus} = this.props;
        return (
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    >
                    {column.id === 'checkbox' ?
                    (<Checkbox
                        checked={selectAllCheckboxStatus}  
                        onChange={(event)=> this.handleAllCheckbox(event)} 
                        value={'checkedB'} color="primary"  
                        inputProps={{ 'aria-label': 'secondary checkbox',}} 
                        />)
                    :
                    (column.label)
                    }
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
        )
    }

    mapDataPerPage() {
        const rows = this.props.rows;
        const rowsPerPage = this.props.rowsPerPage !== undefined ? this.props.rowsPerPage : this.props.rows.length;
        const page = this.props.page !== undefined ? this.props.page : 0;
        return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }

    setSelectedItemRow = (e,row) => {
		this.props.setSelectedItemRow(e,row);
    }
    
    handleAllCheckbox(event) {
        this.props.handleAllCheckbox(event);
    }

    handleCheckboxChange(event, row) {
        this.props.handleCheckboxChange(event, row);
    }

    renderCommonTableBody() {
        const {columns, selectAllCheckboxStatus, componentName, mainAssetId} = this.props;
        return (
            <TableBody>
                {this.mapDataPerPage().map((row, rowIndex) => {
                return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                    {columns.map((item) => {
                        return (
                        <TableCell key={item.id} align={item.align}>
                        {                   
                            (item.id === 'assetId') ? ( <div>
                                    {row[item.id] ? (componentName==="payOff")? (<span>{row[item.id]}</span>) : (<span onClick={() => this.props.handleRowClick(row)} className="link">{row[item.id]}</span>) : this.renderNoContent()} </div>
                            ):(item.id === 'checkbox') 
                            ?(<Checkbox 
                                checked={row.checkStatus || selectAllCheckboxStatus}  
                                onChange={(event)=> this.handleCheckboxChange(event, row)}
                                color="primary"  
                                inputProps={{ 'aria-label': 'secondary checkbox',}} 
                                disabled={mainAssetId === row.assetId }
                            />)
                            :(item.id === 'ownerDetails') ? (
                                <div> {row[item.id] ? (<SimplePopover component='table' dropDownTitle="Owner" dropDownColumnName="ownerName" dropDownList={row[item.id]} />) : this.renderNoContent()} </div>
                            ):(item.id === 'postingAmount') ? (
                                <div> {row[item.id] ? (<span>{symbolWithTwoDecimal(row[item.id])}</span>) : this.renderNoContent()} </div>
                            ):(item.id === 'parcelId') ? (
                                <div> {row['propertyDetails'] ? (<SimplePopover component='table' dropDownTitle="Parcel Id" dropDownColumnName="parcelId" dropDownList={row['propertyDetails']} />) : this.renderNoContent()}
                                </div>):(item.id === 'propertyDetails') ? ( <div>
                                    {row[item.id] ? (<SimplePopover component='table' dropDownTitle="Property Address" dropDownColumnName="propertyAddress" dropDownList={row[item.id]} />) : this.renderNoContent()}
                                </div>
                            ):(item.id === 'propertyType') ? (<div>{row['propertyDetails'] ? (<SimplePopover component='table' dropDownTitle="Property Type" dropDownColumnName="propertyType" dropDownList={row['propertyDetails']} />) 
                            : this.renderNoContent()} </div>
                            ):(item.id === 'mailingAddress') ? (
                                <div>{row['ownerDetails'] ? (<SimplePopover component='table' dropDownTitle="Owner Address" dropDownColumnName="ownerAddress" dropDownList={row['ownerDetails']} />) : this.renderNoContent()} </div>
                            ):(item.id === 'currentInterestRate') ? (
                                <div>{row[item.id] ? (row['recordRowId']==='last') ? (<span>{row[item.id]}</span>) : (<span>{`${row[item.id]}%`}</span>) : this.renderNoContent()} </div>
                            ):(item.id === 'action' && this.props.isDownloadButtonShow) ? (
                                <div><IconButton 
                                size='small'
                                onClick={(event) => this.setSelectedItemRow(event,row)}>
                                <img alt="View Document" src={ViewDocumentIcon} /></IconButton></div>
                            ):(<div>{row[item.id] ? (<span>{item.numeric ?  symbolWithTwoDecimal(row[item.id]) : row[item.id]}</span>):(item.numeric ? (<span>$0.00</span>):(this.renderNoContent()))}</div>)
                        }	
                            {/* {column.format && typeof value === 'number' ? column.format(value) : value} */}
                        </TableCell>
                        );
                    })
                }
                    </TableRow>
                );
                })}
            </TableBody>
        )
    }

    renderNoContent() {
        return (<span className="no-content"> - </span>);
    }

    render() {
        let {rows, stylingClass} = this.props
        return (
            <Fragment>
                {rows && rows.length> 0 ? 
                <TableContainer className={`common-table ${stylingClass}`}>
                    <Table stickyHeader aria-label="sticky table">
                        {this.renderTableHead()}
                        {this.renderCommonTableBody()}
                    </Table>
                </TableContainer>
                : <NoRecordFound classes={'table-no-record'} buttonText={'NO RECORD FOUND'} showButton={false} />
            }
            </Fragment>
        )
    }
}

export default CommonTable;