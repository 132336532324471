import {
    GET_ASSET_DETAILS, GET_ASSET_DETAILS_SUCCESS, GET_ASSET_DETAILS_FAILURE
} from '../actions/assetDetailsAction';

import {mapSubHeaderDetails} from '../common/util';

const INITIAL_STATE = {
    assetDetails: {},
    subHeaderDetails: {},
    success: null,
    error: null,
    loading: false
}

export default function assetDetailsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case GET_ASSET_DETAILS:
            return { ...state, assetDetails: {},subHeaderDetails: {},
            error: null, loading: true };
        case GET_ASSET_DETAILS_SUCCESS:
            return { ...state, 
                assetDetails: action.payload.entity,
                subHeaderDetails: mapSubHeaderDetails(action.payload.entity),
                error: null, 
                loading: false 
            };
        case GET_ASSET_DETAILS_FAILURE:
            return { ...state, assetDetails: {},subHeaderDetails: {},
            error: action.payload.message, loading: false };
            default:
            return state;
    }
}