export const getAssetListApi = 'posts'; //mock-api-url
export const getAssetDetailsApi = 'asset'
export const getJurisdictionDataAPI = 'jurisdiction'; //mock-api-url
export const getSearchDataAPI = 'search';
export const postCertificateDownlaodAPI = 'document/download';
export const postContactAPI = 'contactus';
export const getCaptchaDataAPI = 'Get';
export const postPayOffGeneratorAPI = 'MailMerge/Post';
export const getPayOffDownloadAPI = 'MailMerge';
export const postDocumentUploadAPI = 'DocumentUpload';



