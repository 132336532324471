//reducers for assets list
import {
    SHOW_SNACKBAR,
    HIDE_SNACKBAR,
    // UPDATE_SNACKBAR,
    // DELETE_SNACKBAR,
    ERROR_SNACKBAR
} from '../actions/customSnackBarAction';

const INITIAL_STATE = {
    open: false,
    message: '',
    messageType: ''
}

export default function customSnackBarReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case SHOW_SNACKBAR:
            return { ...state, open: true, message: action.payload, messageType: 'success' };
        // case UPDATE_SNACKBAR:
        //         return { ...state, open: true, message: action.payload, messageType: 'update' }
        // case DELETE_SNACKBAR:
        //             return { ...state, open: true, message: action.payload, messageType: 'delete' }
        case ERROR_SNACKBAR:
                        return { ...state, open: true, message: action.payload, messageType: 'error' }
        case HIDE_SNACKBAR:
            return { ...state, open: false, message: ''}
        default:
            return state;
    }
}
