import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import {contactFormDataJson, EMAIL_REGEX, PHONE_REGEX} from '../const/commonConst'
import MaskedInput from 'react-text-mask';

export class Contact extends Component {
    constructor(props) {
		super(props)
		this.state = {
            assetId: '',
            formDataIsValid: {
                name: false,
                phoneNumber: false,
                email: false,
                reason: false,
                query: false
            },
            required: ['assetId', 'name', 'email','phoneNumber','reason', 'query'],
            reasonList: [
                {label: 'General Inquiry', value: 'General Inquiry'},
                {label: 'Payment Plan Request', value: 'Payment Plan Request'},
                {label: 'Payoff Quote Request', value: 'Payoff Quote Request'},
                {label: 'Payment Inquiry', value: 'Payment Inquiry'},
            ],
		}
    }

    componentDidMount() {
        const {assetDetails, isRedirectFromPayOff, assetIds} = this.props
        this.setState({
            assetId: isRedirectFromPayOff ? assetIds.toString() : assetDetails.assetId
        })
        let contactFormData = {...contactFormDataJson};
        contactFormData.reason = isRedirectFromPayOff ? this.state.reasonList[2].value : '';
        contactFormData.reasonCode = isRedirectFromPayOff ? this.state.reasonList[2].value.replaceAll(" ", "") : '';
        contactFormData.isPayOffQuoteRequested = isRedirectFromPayOff;
        this.props.updateContactFormData(contactFormData);
    }

    componentWillUnmount() {
        this.props.cleanRedirectFromPayOff();
    }
   
    handleChange(e) {
        const {name, value, required} = e.target;
        let contactFormData = {...this.props.contactFormData}
        let newFormDataIsValid = {...this.state.formDataIsValid}
        contactFormData[name] = name === 'phoneNumber' ? value === `(   )    -    ` ? '' : value : value;
        if (required || name==='phoneNumber') {
            newFormDataIsValid[name] = this.isValidData(name, value);
        }
        if(name==='reason') {
            contactFormData['reasonCode'] = value.replaceAll(" ", "");
        }
        this.props.updateContactFormData(contactFormData);
        this.setState({formDataIsValid: newFormDataIsValid});
    }

    isValidData(name, value) {
        let isNotValid=null;
        if(name === 'email') {
            isNotValid = value.match(EMAIL_REGEX);
        } else if(name==='phoneNumber') {
            isNotValid = value === `(   )    -    ` || value.match(PHONE_REGEX);
        } else {
            isNotValid = value===""?null:true;
        }
        return isNotValid === null? true : false;
    }

    handleSubmit() {
        const {contactFormData, assetDetails, isRedirectFromPayOff, assetIds} = this.props
        const {formDataIsValid, required} = this.state;
        let isError = false;
        Object.keys(contactFormData).forEach(item => {
            if (required.find(i=>i===item)) {
                formDataIsValid[item] = this.isValidData(item,contactFormData[item]);
                if(formDataIsValid[item]) {
                    isError = true;
                }
            }
        });
        if(!isError) {
            let postdata = {...contactFormData, assetId: isRedirectFromPayOff ? assetIds.toString() : assetDetails.assetId}
            this.props.postContactForm(postdata, isRedirectFromPayOff).then((data)=>{
                if(data.isSuccess) {
                    this.props.updateContactFormData(contactFormDataJson);
                }
            })
        } else {
            this.setState({formDataIsValid: formDataIsValid});
        }
    }

    TextMaskCustom(props) {
        const { inputRef, ...other } = props;
      
        return (
          <MaskedInput
            {...other}
            ref={(ref) => {
              inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
          />
        );
      }

    render() {
        const{formDataIsValid, assetId} = this.state;
        const {name,phoneNumber,email,reason,query} = this.props.contactFormData;
        const {isRedirectFromPayOff} = this.props;
        let isButtonDisabled = name === "" || email === "" || phoneNumber === "" || reason === "" || query === "" || formDataIsValid.phoneNumber || formDataIsValid.email;
        return (
            <Fragment>
                <div className='heading-section'>
                    <Box component='div' className="heading-title"><Typography variant='h1'>Contact Us</Typography></Box>
                    <Box component='div' className="heading-buttons">
                        <Button disabled={isButtonDisabled} className="submit-btn" variant="contained" color="primary" onClick={()=>this.handleSubmit()}>SUBMIT</Button>
                        {/* <Button>CANCEL</Button> */}
                    </Box>
                </div>
                <Paper className="custom-paper">
                    <List className="detail-list contact-list">
                        <ListItem className="detail-list-item">
                            <TextField required id="assetId" label="Lien ID" value={assetId} disabled />
                        </ListItem>
                        <ListItem className="detail-list-item">
                            <TextField required id="name" name="name" label="Name" inputProps={{maxLength:100}} error={formDataIsValid.name} value={name} onChange={(e)=>this.handleChange(e)} />
                        </ListItem>
                        <ListItem className="detail-list-item">
                            <TextField required id="phoneNumber" name="phoneNumber" label="Phone" error={formDataIsValid.phoneNumber} value={phoneNumber} onChange={(e)=>this.handleChange(e)}
                            InputProps={{
                                inputComponent: this.TextMaskCustom,
                            }}
                             />
                        </ListItem>
                        <ListItem className="detail-list-item">
                            <TextField required id="email" name="email" label="Email" error={formDataIsValid.email} value={email} onChange={(e)=>this.handleChange(e)} />
                        </ListItem>
                        <ListItem className="detail-list-item">
                            <FormControl required disabled={isRedirectFromPayOff}>
                                <InputLabel id="reason-label">Reason</InputLabel>
                                <Select
                                className="reason"
                                labelId="reason-label"
                                id="reason"
                                name="reason"
                                value={reason}
                                onChange={(e)=>this.handleChange(e)} >
                                {this.state.reasonList.map((option,i) => (
                                    <MenuItem key={`reason-option-${i}`} value={option.value} >
                                        {option.label}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </ListItem>
                    </List>
                    <List className="detail-list contact-list">
                        <ListItem className="detail-list-item">
                            <TextField required id="query" name="query" inputProps={{maxLength:1000}} label="Query/Feedback" multiline rows={4} error={formDataIsValid.query} value={query} onChange={(e)=>this.handleChange(e)} />
                        </ListItem>
                    </List>
                </Paper>
            </Fragment>
        )

    }
}

export default Contact;
