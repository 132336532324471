import React, { Component, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ThreeDotsIcon from '../assets/icons/three-dots.svg'
import { getDropDownColumnName} from "../common/util";


class SimplePopover extends Component {

    constructor(props) {
		super(props)
		this.state = {
            open: false,
            anchorEl: '',
            id: 'simple-popover',
        }
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false
        })
    }

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            open: true,
        })
    }

    listLength() {
        const {dropDownList, dropDownColumnName} = this.props;
        let num = 0;
        if (dropDownList.length > 0) {
            dropDownList.forEach(list => {
                num = list[dropDownColumnName] !== '' ? num+1 : num;
            });
        }
        return num;
    }

    renderPopoverOpener() {
        const {dropDownList, dropDownColumnName, component} = this.props;
        const {id, open} = this.state;
        return (
            <Fragment>
                {component === 'subHeader-on-small-screen'? 
                <Fragment>
                    <IconButton className="popover-button" size="small" aria-describedby={id} onClick={this.handleClick}>
                        <img alt="More Details" src={ThreeDotsIcon} />
                    </IconButton>
                </Fragment>
                :
                <Fragment>
                    <span>{dropDownList[0][dropDownColumnName] !== '' ? dropDownList[0][dropDownColumnName] : '-'}</span>
                    {this.listLength() > 1 && component === 'table' &&
                        <IconButton className="popover-icon" size="small" aria-owns={open ? id : undefined} aria-haspopup="true" onMouseEnter={this.handleClick} onMouseLeave={this.handleClose}>
                            {` +${this.listLength()-1}`}
                        </IconButton>
                    }
                    {this.listLength() > 1 && component !== "table" &&
                        <IconButton className="popover-icon" size="small" aria-describedby={id} onClick={this.handleClick}>
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                </Fragment>
                }
            </Fragment>
        )
    }

    renderPopoverList() {
        const {dropDownList, dropDownTitle, dropDownColumnName} = this.props;
        return (
            <Fragment>
                {dropDownList.length > 0 && dropDownList.map((item, i)=> {
                        return (
                            <div className={`popover-list-item`} key={"row" + i}>
                                <h4>{dropDownTitle} {i+1}{':'}</h4>
                                <p>{item[dropDownColumnName] !== '' ? item[dropDownColumnName] : '-'}</p>
                            </div>
                        )
                    })
                }
            </Fragment>
        )
    }

    renderSubHeaderPopoverData() {
        const {dropDownList} = this.props;
        return (
            <Fragment>
                {Object.keys(dropDownList).map((item, i)=> {
                    return (
                        <Fragment key={`row-Fragment-${i}-${getDropDownColumnName(item)}`}>
                            {i>1 && 
                            <div className={`popover-list-item`} key={`row-${i}-${getDropDownColumnName(item)}`}>
                                <h4>{item} {':'}</h4>
                                {Array.isArray(dropDownList[item])?
                                    <Fragment>
                                        {dropDownList[item].length > 0 && dropDownList[item].map((subItem, index)=> {
                                                return (
                                                    <div className={`popover-list-item-inner`} key={"innerrow" + index}>
                                                        <span className="inner-title">{index+1}{':'}</span>
                                                        <span>{subItem[getDropDownColumnName(item)] !== '' ? subItem[getDropDownColumnName(item)] : '-'}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Fragment>
                                :
                                    <p><span>{dropDownList[item] !== '' ? dropDownList[item] : '-'}</span>
                                    {item ==="Remaining Balance" && <Tooltip arrow enterTouchDelay={10} leaveTouchDelay={2000} title="The Remaining Balance set forth above may be greater than the amount shown herein and is subject to change due to unknown costs or expenses" placement="bottom"><InfoOutlinedIcon /></Tooltip>}
                                    </p>
                                }
                            </div>
                            }
                        </Fragment>
                    )
                })}
            </Fragment>
        )
    }

    render() {
        const {component} = this.props;
        const {open, anchorEl, id} = this.state;
        return (
            <Fragment>
                {this.renderPopoverOpener()}
                <Popover
                    className={`simple-popover-dropdown-wrapper ${component === 'table' && 'hover'}`}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    disableRestoreFocus
                >
                    <div className="popover-list">
                        {component==='subHeader-on-small-screen' ? this.renderSubHeaderPopoverData() : this.renderPopoverList()}
                    </div>
                </Popover>
            </Fragment>  
        )
    }
}

export default SimplePopover;