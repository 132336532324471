import { doActionGet, doActionGetWithCaptcha } from '../services/httpRequest';
import { getJurisdictionDataAPI, getSearchDataAPI, getCaptchaDataAPI } from '../services/apiUrls';
import APIConfig from '../services/appConfig';

export const FETCH_GLOBAL_SEARCH_DATA = 'FETCH_GLOBAL_SEARCH_DATA';
export const FETCH_GLOBAL_SEARCH_DATA_SUCCESS = 'FETCH_GLOBAL_SEARCH_DATA_SUCCESS';
export const FETCH_GLOBAL_SEARCH_DATA_FAILURE = 'FETCH_GLOBAL_SEARCH_DATA_FAILURE';
export const FETCH_COUNTY_LIST = 'FETCH_COUNTY_LIST';
export const FETCH_COUNTY_LIST_SUCCESS = 'FETCH_COUNTY_LIST_SUCCESS';
export const FETCH_COUNTY_LIST_FAILURE = 'FETCH_COUNTY_LIST_FAILURE';
export const FETCH_CAPTCHA_IMAGE = 'FETCH_CAPTCHA_IMAGE';
export const FETCH_CAPTCHA_IMAGE_SUCCESS = 'FETCH_CAPTCHA_IMAGE_SUCCESS';
export const FETCH_CAPTCHA_IMAGE_FAILURE = 'FETCH_CAPTCHA_IMAGE_FAILURE';
export const UPDATE_CAPTCHA_TEXT = 'UPDATE_CAPTCHA_TEXT';

//fetch county list from API
export const getCountyList = () => {
	const response = doActionGet({ url: getJurisdictionDataAPI + '?stateCode=OH' }, APIConfig.ROOT_URL);
	return {
		type: FETCH_COUNTY_LIST,
		payload: response
	}
}

//method call when county list successfully fetched
export const getCountyListSuccess = (data) => {
	return {
		type: FETCH_COUNTY_LIST_SUCCESS,
		payload: data
	}
}

//method call when county list fail to access
export const getCountyListFailure = (error) => {
	return {
		type: FETCH_COUNTY_LIST_FAILURE,
		payload: error
	}
}

//fetch global search data from API
export const getGlobalSearchData = (selectedCounty, searchValue, captchaText, captchaData) => {
	const response = doActionGetWithCaptcha({ url: getSearchDataAPI + '?StateCode=OH&JurisdictionCode='+selectedCounty+'&SearchValue='+searchValue}, APIConfig.ROOT_URL, captchaText, captchaData);
	return {
		type: FETCH_GLOBAL_SEARCH_DATA,
		payload: response
	}
}

//method call when global search successfully fetched
export const getGlobalSearchDataSuccess = (data) => {
	return {
		type: FETCH_GLOBAL_SEARCH_DATA_SUCCESS,
		payload: data
	}
}

//method call when global search fail to access
export const getGlobalSearchDataFailure = (error) => {
	return {
		type: FETCH_GLOBAL_SEARCH_DATA_FAILURE,
		payload: error
	}
}

//fetch captcha image from API
export const getCaptchaImage = () => {
	const response = doActionGet({ url: getCaptchaDataAPI }, APIConfig.ROOT_URL);
	return {
		type: FETCH_CAPTCHA_IMAGE,
		payload: response
	}
}

//method call when captcha image successfully fetched
export const getCaptchaImageSuccess = (data) => {
	return {
		type: FETCH_CAPTCHA_IMAGE_SUCCESS,
		payload: data
	}
}

//method call when captcha image fail to access
export const getCaptchaImageFailure = (error) => {
	return {
		type: FETCH_CAPTCHA_IMAGE_FAILURE,
		payload: error
	}
}

//method call to update the captcha text
export const updateCaptchaText = (data) => {
	return {
		type: UPDATE_CAPTCHA_TEXT,
		payload: data
	}
}
