//contains all https request
import axios from 'axios';
import {getRefreshToken} from '../common/requireAuth';
import FileSaver from 'file-saver';


axios.interceptors.response.use( (response) => {
    // Return a successful response back to the calling service
    return response;
    }, (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Logout user if token refresh didn't work or user is disabled
    if (error.config.url === '/api/token/refresh' || error.response.message === 'Account is disabled.') {
      //TokenStorage.clear();
      //router.push({ name: 'root' });
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Try request again with new token
    return getRefreshToken()
      .then((token) => {
        // New request with new token
        const config = error.config;
        config.headers['token'] = token;

        return new Promise((resolve, reject) => {
          axios.request(config).then(response => {
            resolve(response);
          }).catch((err) => {
            reject(err);
          })
        });

      })
      .catch((err) => {
      	Promise.reject(err);
      });
  });


/**
* action for http request for get
* @param {*} config
*/
export function doActionGet(config, RedirectURL) {
  if (config) {
    return axios({
      url: `${RedirectURL}/` + config.url,
      method: 'GET',
      headers: {
        token: getRefreshToken()
      }
    });
  }
}

export function doActionGetWithCaptcha(config, RedirectURL, captchaText, captchaData) {
  if(config) {
    return axios({
      url: `${RedirectURL}/` + config.url,
      method: 'GET',
      headers: {
        captcha: captchaText,
        captchaData: captchaData,
        token: getRefreshToken()
      }
    });
  }
}


/**
* action for http request for post
* @param {*} config
*/
export function doActionPost(config,RedirectURL) {
    if (config) {
        return axios({
            url: `${RedirectURL}/` + config.url,
            method: 'POST',
            data: config.data,
            headers: {
              token: getRefreshToken(),
              'Content-Type': 'application/json'
            }           
        });
    }
}

/**
* action for http request for put
* @param {*} config
*/
export function doActionPut(config,RedirectURL) {
    if (config) {
        return axios({
            url: `${RedirectURL}/` + config.url,
            method: 'PUT',
            data: config.data,
            headers: {
              token: getRefreshToken(),
              'Content-Type': 'application/json'
            } 
        });
    }
}

/**
* action for http request for post
* @param {*} config
*/
export function doActionPatch(config,RedirectURL) {
    if (config) {
        return axios({
            url: `${RedirectURL}/` + config.url,
            method: 'PATCH',
            data: config.data,
            headers: {
              token: getRefreshToken(),
              'Content-Type': 'application/json'
            }
        });
    }
}

/**
* action for http request for delete
* @param {*} config
*/
export function doActionDelete(config,RedirectURL) {
    if (config) {
        return axios({
            url: `${RedirectURL}/` + config.url,
            method: 'DELETE',
            data: config.data,
            headers: {
              token: getRefreshToken(),
              'Content-Type': 'application/json'
            }
        });
    }
}


export function doActionGetDownload(config, fileName) {
	if (config) {
		axios.get(config, {
			responseType: 'blob',
		}).then(response => {
            //const filename = fileName ? fileName : config.Path.replace(/^.*[\\\/]/, '');
            if(response !== undefined) {
                FileSaver.saveAs(response.data, fileName);
            }
			return response
		});
	}
}
